import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import "./styles/riskscribe.scss";

import Assessment from "./components/Assessment";
import AssessmentList from "./components/AssessmentList";
import Contact from "./components/Contact";
import CriteriaEditor from "./components/CriteriaEditor";
import Home from "./components/Home";
import Login from "./components/Login";
import License from "./components/License";
import LicenseResults from "./components/LicenseResults";
import Menu from "./components/Menu";
import PlanEditor from "./components/PlanEditor";
import Register from "./components/Register";
import Reset from "./components/Reset";
import Settings from "./components/Settings";

import { getUser } from "./services/RiskScribeSDK";
import { languageConfiguration } from "./services/i18n";

const PrivateRoute = ({ element: Component, loggedIn, ...rest }) => {
  if (loggedIn !== null) {
    return loggedIn ? <Component {...rest} /> : <Navigate to="/login" />;
  } else return null;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [language, setLanguage] = useState("en");
  const [assessments, setAssessments] = useState(null);
  const [customCriteria, setCustomCriteria] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [tier, setTier] = useState(0);
  const [expiration, setExpiration] = useState(0);
  const [shouldStartNewAssessment, setShouldStartNewAssessment] = useState(false);

  useEffect(() => {
    const checkOpenSession = async () => {
      try {
        let userData = await getUser();
        setLoggedIn(true);
        setUserEmail(userData._id);
        setAssessments(userData.assessments);
        setCustomCriteria(userData.criteria);
        setTier(userData.tier);
        setExpiration(userData.expiration);
        languageConfiguration(userData.language);
        setLanguage(userData.language);
      } catch (error) {
        setLoggedIn(false);
        languageConfiguration(navigator.language.slice(0, 2));
        setLanguage(navigator.language.slice(0, 2));
      }
    };
    checkOpenSession();
  }, []);

  const onLogout = () => {
    setLoggedIn(false);
    setUserEmail(null);
    setAssessments(null);
    setCustomCriteria(null);
    languageConfiguration(navigator.language.slice(0, 2));
    setLanguage(navigator.language.slice(0, 2));
  };

  const onLoginSuccess = (userData) => {
    setLoggedIn(true);
    setUserEmail(userData._id);
    setAssessments(userData.assessments);
    setCustomCriteria(userData.criteria);
    setTier(userData.tier);
    setExpiration(userData.expiration);
    languageConfiguration(userData.language);
    setLanguage(userData.language);
  };

  return (
    <Router basename={window.location.pathname.startsWith("/app") ? "/app" : "/"}>
      <Menu
        loggedIn={loggedIn}
        email={userEmail}
        onLogout={onLogout}
        language={language}
        setShouldStartNewAssessment={setShouldStartNewAssessment}
        preventNewAssessment={tier === 0 && assessments?.length !== 0}
      />
      <Routes>
        <Route path="/" element={<Home loggedIn={loggedIn} />} />
        <Route
          path="/list"
          element={
            <PrivateRoute
              element={AssessmentList}
              loggedIn={loggedIn}
              assessments={assessments}
              selectAssessment={setSelectedAssessment}
              updateAssessments={setAssessments}
              customCriteria={customCriteria}
              user={userEmail}
              tier={tier}
              language={language}
              shouldStartNewAssessment={shouldStartNewAssessment}
              setShouldStartNewAssessment={setShouldStartNewAssessment}
            />
          }
        />
        <Route path="/register" element={<Register onRegisterSuccess={onLogout} language={language} />} />
        <Route path="/login" element={<Login onLoginSuccess={onLoginSuccess} onLogout={onLogout} language={language} />} />
        <Route path="/reset" element={<Reset onResetSuccess={onLogout} />} />
        <Route path="/settings" element={<PrivateRoute element={Settings} loggedIn={loggedIn} updateLanguage={setLanguage} language={language} />} />
        <Route path="/license" element={<PrivateRoute element={License} loggedIn={loggedIn} language={language} tier={tier} expiration={expiration} />} />
        <Route path="/contact" element={<PrivateRoute element={Contact} loggedIn={loggedIn} />} />
        <Route
          path="/assessment"
          element={
            <PrivateRoute
              element={Assessment}
              loggedIn={loggedIn}
              assessmentId={selectedAssessment?.id}
              isEditable={(selectedAssessment?.permissions & 129) !== 0}
              language={language}
            />
          }
        />
        <Route
          path="/criteria"
          element={
            <PrivateRoute
              element={CriteriaEditor}
              loggedIn={loggedIn}
              customCriteria={customCriteria}
              updateCustomCriteria={setCustomCriteria}
              tier={tier}
              language={language}
            />
          }
        />
        <Route path="/plan" element={<PrivateRoute element={PlanEditor} loggedIn={loggedIn} assessmentId={selectedAssessment?.id} language={language} />} />
        <Route path="/license/success" element={<LicenseResults loggedIn={loggedIn} success={true} onLogout={onLogout} />} />
        <Route path="/license/cancel" element={<LicenseResults loggedIn={loggedIn} success={false} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
