import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { contact } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const message = document.getElementById("message").value;
    try {
      setIsLoading(true);
      await contact(message);
      setIsLoading(false);
      setMessageSent(true);
    } catch (error) {
      setIsLoading(false);
      setSendError(true);
    }
  };

  return (
    <>
      <div className="container mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <h1 className="is-size-3 font-rubik">{t("Contact")}</h1>
              <p className="mb-2">
                {t("We are here to help you with any issues you are having with the RiskScribe platform or listen to your improvement suggestions.")}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label" htmlFor="message">
                    {t("Message")}:
                  </label>
                  <div className="control">
                    <textarea className="textarea is-primary" rows={10} id="message" autoFocus />
                  </div>
                </div>
                <div className="field is-flex is-justify-content-center">
                  <div className="control">
                    <button className={`button is-primary ${isLoading ? "is-loading" : ""}`}>{t("Send")}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Warning show={sendError} onConfirm={() => setSendError(false)} title={t("Error")} content={t("Unable to send your message, please try again later")} />

      <Warning
        show={messageSent}
        onConfirm={() => navigate("/")}
        title={t("Notice")}
        content={t("Your message has been sent, we'll be in touch shortly to your registered email.")}
      />
    </>
  );
};

export default Contact;
