import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { createCriteria } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

import { internalCriteria } from "../utilities/defaults";

const NewCriteria = ({ customCriteria, updateCustomCriteria, language, onClose }) => {
  const defaultCriteria = internalCriteria(language);

  const [newCriteria, setNewCriteria] = useState({
    name: "",
    probability: {
      total: 3,
      names: ["1", "2", "3"],
      descriptions: ["", "", ""],
    },
    impact: {
      total: 3,
      names: ["1", "2", "3"],
      descriptions: ["", "", ""],
    },
    exposure: {
      probability: 50,
      impact: 50,
    },
    tolerance: {
      caution: 1.5,
      unacceptable: 2.5,
    },
  });

  const [isCopy, setIsCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showBadName, setShowBadName] = useState(false);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!showBadName) {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          onConfirm();
        }

        if (event.key === "Escape") {
          event.preventDefault();
          event.stopPropagation();
          onClose();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [newCriteria]);

  const onConfirm = async () => {
    if (
      newCriteria.name.trim() !== "" &&
      defaultCriteria.every((c) => c.name !== newCriteria.name) &&
      customCriteria.every((c) => c.name !== newCriteria.name)
    ) {
      setIsLoading(true);
      try {
        await createCriteria(newCriteria);
        updateCustomCriteria((prev) => [...prev, newCriteria]);
        setIsLoading(false);
        onClose();
      } catch (error) {
        setIsLoading(false);
        if (error.name === "APIError") {
          if (error.status === 401) {
            setShowSessionExpired(true);
          } else if (error.status === 403) {
            setShowAuthorizationError(true);
          } else {
            setShowServerError(true);
          }
        } else {
          setShowNetworkProblem(true);
        }
      }
    } else {
      setShowBadName(true);
    }
  };

  const handleCategories = (event) => {
    let categories = +event.target.value;

    setNewCriteria((prev) => ({
      ...prev,
      probability: {
        total: categories,
        names: Array.from({ length: categories }, (_, index) => (index + 1).toString()),
        descriptions: Array.from({ length: categories }, (_) => ""),
      },
      impact: {
        total: categories,
        names: Array.from({ length: categories }, (_, index) => (index + 1).toString()),
        descriptions: Array.from({ length: categories }, (_) => ""),
      },
      tolerance: {
        caution: Math.round(((categories + 1) / 2 - categories / 6) * 10) / 10,
        unacceptable: Math.round(((categories + 1) / 2 + categories / 6) * 10) / 10,
      },
    }));
  };

  const handleSelectCopy = (event) => {
    let copyName = event.target.value;

    let criteria = [...defaultCriteria, ...customCriteria].find((c) => c.name === copyName);
    let copyCriteria = JSON.parse(JSON.stringify(criteria));

    copyCriteria.name = newCriteria.name;

    setIsCopy(true);
    setNewCriteria(copyCriteria);
  };

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head has-text-centered">
            <span className="modal-card-title font-rubik">{t("New risk assessment criteria")}</span>
          </header>
          <form>
            <section className="modal-card-body">
              <div className="field">
                <label className="label" htmlFor="name">
                  {t("Name")}:
                </label>
                <div className="control">
                  <input
                    id="name"
                    type="text"
                    className="input is-primary"
                    value={newCriteria.name}
                    onChange={(e) => setNewCriteria((prev) => ({ ...prev, name: e.target.value }))}
                    required
                    autoFocus
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="copy">
                  {t("Criteria to copy as basis")}:
                </label>
                <div className="control">
                  <div className="select is-primary">
                    <select id="copy" onChange={handleSelectCopy}>
                      {!isCopy && <option>{t("None ")}</option>}
                      {[...defaultCriteria, ...customCriteria].map((c) => (
                        <option key={c.name}>{c.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {!isCopy && (
                <div className="field">
                  <label className="label" htmlFor="categories">
                    {t("Number of categories")}:
                  </label>
                  <div className="control">
                    <div className="select is-primary">
                      <select id="categories" onChange={handleCategories}>
                        {Array.from({ length: 8 }, (_, index) => index + 3).map((i) => (
                          <option key={i}>{i}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </section>
            <footer className="modal-card-foot has-background-white is-flex is-justify-content-space-evenly">
              <div className="control">
                <button type="button" className="button is-link confirmation" onClick={onClose}>
                  {t("Cancel")}
                </button>
              </div>
              <div className="control">
                <button type="button" className={`button is-primary ${isLoading ? "is-loading" : ""}`} onClick={onConfirm}>
                  {t("OK")}
                </button>
              </div>
            </footer>
          </form>
        </div>
      </div>

      <Warning
        show={showBadName}
        onConfirm={() => setShowBadName(false)}
        title={t("Error")}
        content={t("A criteria cannot be created without a name or with a duplicate one")}
      />

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
    </>
  );
};

export default NewCriteria;
