import React, { useState, useEffect } from "react";

import { t } from "../services/i18n";

const Input = ({ title, initialValue, onConfirm }) => {
  const [inputText, setInputText] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        onConfirm(inputText);
      }

      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        onConfirm(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputText]);

  const returnText = () => {
    setIsLoading(true);
    onConfirm(inputText);
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head has-text-centered">
          <span className="modal-card-title font-rubik">{title}</span>
        </header>
        <form>
          <section className="modal-card-body">
            <input type="text" className="input is-primary" value={inputText} onChange={(e) => setInputText(e.target.value)} autoFocus />
          </section>

          <footer className="modal-card-foot has-background-white is-flex is-justify-content-space-evenly">
            <button type="button" className="button is-link" onClick={() => onConfirm(null)}>
              {t("Cancel")}
            </button>
            <button type="button" className={`button is-primary ${isLoading ? "is-loading" : ""}`} onClick={returnText}>
              {t("OK")}
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

export default Input;
