import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";

import HeatMapPreview from "./HeatMapPreview";
import NewCriteria from "./NewCriteria";
import Warning from "./Warning";

import { updateCriteria, deleteCriteria } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

import debounce from "../utilities/debounce";
import { internalCriteria } from "../utilities/defaults";

const CriteriaEditor = ({ customCriteria, updateCustomCriteria, language, tier }) => {
  const navigate = useNavigate();

  const defaultCriteria = internalCriteria(language);

  const [currentCriteria, setCurrentCriteria] = useState(null);
  const [shouldUpdateFlag, setShouldUpdateFlag] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [cautionTemp, setCautionTemp] = useState(defaultCriteria[0].tolerance.caution);
  const [unacceptableTemp, setUnacceptableTemp] = useState(defaultCriteria[0].tolerance.unacceptable);
  const [newCriteria, setNewCriteria] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);

  const changeCriteria = (newCriteria) => {
    updateCriteria(newCriteria).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const debouncedUpdateCriteria = useRef(debounce(changeCriteria, 1000)).current;

  useEffect(() => {
    if (shouldUpdateFlag) {
      updateCustomCriteria((prev) => prev.map((c) => (c.name === currentCriteria.name ? currentCriteria : c)));
      debouncedUpdateCriteria(currentCriteria);
      setShouldUpdateFlag(false);
    }
  }, [currentCriteria, shouldUpdateFlag]);

  const handleProbabilityNameChange = (newValue, index) => {
    if (newValue !== "") {
      setCurrentCriteria((prev) => {
        const newCriteria = {
          ...prev,
          probability: {
            ...prev.probability,
            names: prev.probability.names.map((item, i) => (i === index ? newValue : item)),
          },
        };

        setShouldUpdateFlag(true);

        return newCriteria;
      });
    }
  };

  const handleProbabilityDescriptionChange = (newValue, index) => {
    setCurrentCriteria((prev) => {
      const newCriteria = {
        ...prev,
        probability: {
          ...prev.probability,
          descriptions: prev.probability.descriptions.map((item, i) => (i === index ? newValue : item)),
        },
      };

      setShouldUpdateFlag(true);

      return newCriteria;
    });
  };

  const handleImpactNameChange = (newValue, index) => {
    if (newValue !== "") {
      setCurrentCriteria((prev) => {
        const newCriteria = {
          ...prev,
          impact: {
            ...prev.impact,
            names: prev.impact.names.map((item, i) => (i === index ? newValue : item)),
          },
        };

        setShouldUpdateFlag(true);

        return newCriteria;
      });
    }
  };

  const handleImpactDescriptionChange = (newValue, index) => {
    setCurrentCriteria((prev) => {
      const newCriteria = {
        ...prev,
        impact: {
          ...prev.impact,
          descriptions: prev.impact.descriptions.map((item, i) => (i === index ? newValue : item)),
        },
      };

      setShouldUpdateFlag(true);

      return newCriteria;
    });
  };

  const handleProbabilityWeightChange = (event) => {
    let newValue = +event.target.value;

    if (newValue >= 0) {
      setCurrentCriteria((prev) => {
        const newCriteria = {
          ...prev,
          exposure: {
            probability: newValue,
            impact: 100 - newValue,
          },
        };

        setShouldUpdateFlag(true);

        return newCriteria;
      });
    }
  };

  const handleImpactWeightChange = (event) => {
    let newValue = +event.target.value;

    if (newValue >= 0) {
      setCurrentCriteria((prev) => {
        const newCriteria = {
          ...prev,
          exposure: {
            probability: 100 - newValue,
            impact: newValue,
          },
        };

        setShouldUpdateFlag(true);

        return newCriteria;
      });
    }
  };

  const handleCautionChange = (event) => {
    let newText = event.target.value;

    let newValue = +event.target.value;

    if (!isNaN(newValue)) setCautionTemp(newText);

    if (
      newValue >= 1 &&
      newValue <= currentCriteria.impact.total &&
      newValue * 10 === Math.floor(newValue * 10) &&
      newValue !== currentCriteria.tolerance.caution
    ) {
      setCurrentCriteria((prev) => {
        const newCriteria = {
          ...prev,
          tolerance: {
            ...prev.tolerance,
            caution: newValue,
          },
        };

        setShouldUpdateFlag(true);

        return newCriteria;
      });
    }
  };

  const handleUnacceptableChange = (event) => {
    let newText = event.target.value;

    let newValue = +event.target.value;

    if (!isNaN(newValue)) setUnacceptableTemp(newText);

    if (
      newValue >= 1 &&
      newValue <= currentCriteria.impact.total &&
      newValue * 10 === Math.floor(newValue * 10) &&
      newValue !== currentCriteria.tolerance.unacceptable
    ) {
      setCurrentCriteria((prev) => {
        const newCriteria = {
          ...prev,
          tolerance: {
            ...prev.tolerance,
            unacceptable: newValue,
          },
        };

        setShouldUpdateFlag(true);

        return newCriteria;
      });
    }
  };

  const confirmDelete = async () => {
    try {
      await deleteCriteria(currentCriteria.name);
      setShowDeleteWarning(false);
      updateCustomCriteria((prev) => prev.filter((c) => c.name !== currentCriteria.name));
      setCurrentCriteria(null);
    } catch (error) {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    }
  };

  return (
    <>
      <div className="criteria-editor-container">
        <div className="criteria-panel">
          <div className="header">
            <span className="icon-text">
              <span className="is-size-3 font-rubik mb-3 ">{t("Criteria")}</span>
              {tier >= 2 && (
                <Tippy content={t("Add assessment criteria")} theme="riskscribe" placement="right" delay={[1000, 0]}>
                  <span className="icon is-size-4 has-text-link is-clickable ml-3">
                    <i
                      className="fa fa-plus-circle"
                      onClick={() => {
                        setNewCriteria(true);
                      }}
                    ></i>
                  </span>
                </Tippy>
              )}
            </span>
          </div>

          <div className="content">
            {[
              ...defaultCriteria.map((crit) => (
                <div
                  className={`list-items ${crit.name === currentCriteria?.name ? "highlight" : "is-clickable"}`}
                  key={crit.name}
                  onClick={() => {
                    setCurrentCriteria(crit);
                    setCautionTemp(crit.tolerance.caution);
                    setUnacceptableTemp(crit.tolerance.unacceptable);
                    setIsEditable(false);
                  }}
                >
                  {crit.name}
                </div>
              )),
              ...customCriteria.map((crit) => (
                <div
                  className={`list-items ${crit.name === currentCriteria?.name ? "highlight" : "is-clickable"}`}
                  key={crit.name}
                  onClick={() => {
                    setCurrentCriteria(crit);
                    setCautionTemp(crit.tolerance.caution);
                    setUnacceptableTemp(crit.tolerance.unacceptable);
                    setIsEditable(true);
                  }}
                >
                  <span>{crit.name}</span>
                  <Tippy content={t("Delete assessment criteria")} theme="riskscribe" delay={[1000, 0]}>
                    <span className={`icon ${crit.name === currentCriteria?.name ? "is-clickable" : "is-hidden"}`}>
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          setShowDeleteWarning(true);
                        }}
                      ></i>
                    </span>
                  </Tippy>
                </div>
              )),
            ]}
          </div>
        </div>

        {currentCriteria && (
          <div className="criteria-details">
            <div className="criteria-probability">
              <div className="is-size-3 font-rubik mb-3">{t("Probability")}</div>
              {currentCriteria.probability.names.map((_, idx) => (
                <div className="criteria-element" key={idx}>
                  <div>
                    {isEditable ? (
                      <input
                        className="input"
                        value={currentCriteria.probability.names[idx]}
                        onChange={(event) => handleProbabilityNameChange(event.target.value, idx)}
                      />
                    ) : (
                      <div>{currentCriteria.probability.names[idx]}</div>
                    )}
                  </div>
                  <div>
                    {isEditable ? (
                      <textarea
                        className="textarea"
                        rows="1"
                        value={currentCriteria.probability.descriptions[idx]}
                        onChange={(event) => handleProbabilityDescriptionChange(event.target.value, idx)}
                      />
                    ) : (
                      <div>{currentCriteria.probability.descriptions[idx]}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="criteria-impact">
              <div className="is-size-3 font-rubik mb-3">{t("Impact")}</div>
              {currentCriteria.impact.names.map((_, idx) => (
                <div className="criteria-element" key={idx}>
                  <div>
                    {isEditable ? (
                      <input
                        className="input"
                        value={currentCriteria.impact.names[idx]}
                        onChange={(event) => handleImpactNameChange(event.target.value, idx)}
                      />
                    ) : (
                      <div>{currentCriteria.impact.names[idx]}</div>
                    )}
                  </div>
                  <div>
                    {isEditable ? (
                      <textarea
                        className="textarea"
                        rows="3"
                        value={currentCriteria.impact.descriptions[idx]}
                        onChange={(event) => handleImpactDescriptionChange(event.target.value, idx)}
                      />
                    ) : (
                      <div>{currentCriteria.impact.descriptions[idx]}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="criteria-tolerance">
              <div className="criteria-exposure">
                {t("Exposure")} = {t("Probability")} x
                {isEditable ? (
                  <input className="input" minLength="1" maxLength="2" value={currentCriteria.exposure.probability} onChange={handleProbabilityWeightChange} />
                ) : (
                  " " + currentCriteria.exposure.probability
                )}
                % + {t("Impact")} x
                {isEditable ? (
                  <input className="input" minLength="1" maxLength="2" value={currentCriteria.exposure.impact} onChange={handleImpactWeightChange} />
                ) : (
                  " " + currentCriteria.exposure.impact
                )}
                %
              </div>
              <div className="criteria-limits">
                {t("Caution limit")}:
                {isEditable ? <input className="input" value={cautionTemp} onChange={handleCautionChange} /> : " " + currentCriteria.tolerance.caution + " - "}
                {t("Unacceptable limit")}:
                {isEditable ? (
                  <input className="input" value={unacceptableTemp} onChange={handleUnacceptableChange} />
                ) : (
                  " " + currentCriteria.tolerance.unacceptable
                )}
              </div>
              <div className="criteria-heatmap mt-3">
                <HeatMapPreview criteria={currentCriteria} />
              </div>
            </div>
          </div>
        )}
      </div>

      {newCriteria && (
        <NewCriteria customCriteria={customCriteria} updateCustomCriteria={updateCustomCriteria} onClose={() => setNewCriteria(false)} language={language} />
      )}

      <Warning
        show={showDeleteWarning}
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteWarning(false)}
        title={t("Confirmation")}
        content={t("The selected criteria will be deleted")}
      />

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
    </>
  );
};

export default CriteriaEditor;
