import React, { useState, useEffect } from "react";

import { t } from "../services/i18n";

const Warning = ({ show, title, content, onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setIsLoading(false);
    }
  }, [show]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && show) {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        onConfirm();
      }

      if (event.key === "Escape" && show && onCancel) {
        event.preventDefault();
        event.stopPropagation();
        onCancel();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [show, onConfirm]);

  return (
    <div className={`modal ${show ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className={`modal-card-head is-size-5 has-text-centered ${title === t("Confirmation") ? "has-background-danger" : ""}`}>
          <span className={`modal-card-title font-rubik ${title === t("Confirmation") ? "has-text-white" : ""}`}>{title}</span>
        </header>
        <section className={`modal-card-body ${typeof content === "object" ? "" : content.length > 100 ? "has-text-justified" : "has-text-centered"}`}>
          <p>{content}</p>
        </section>
        <footer className="modal-card-foot has-background-white is-flex is-justify-content-space-evenly">
          {onCancel !== undefined && (
            <button className="button is-link" onClick={onCancel}>
              {t("Cancel")}
            </button>
          )}
          <button
            className={`button is-primary ${isLoading ? "is-loading" : ""}`}
            onClick={() => {
              setIsLoading(true);
              onConfirm();
            }}
          >
            {t("OK")}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Warning;
