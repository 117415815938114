import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { register } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

function isValidEmail(email) {
  var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
}

const Register = ({ onRegisterSuccess, language }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const passwordConfirm = document.getElementById("passwordConfirm").value;

    if (!isValidEmail(email)) {
      setIsLoading(false);
      setInvalidEmail(true);
    } else {
      if (!password.length || password !== passwordConfirm) {
        setIsLoading(false);
        setPasswordsDontMatch(true);
      } else {
        try {
          await register(email, password, language, window.location.origin);

          onRegisterSuccess();
          setIsLoading(false);
          setHasRegistered(true);
        } catch (error) {
          setIsLoading(false);
          setRegisterError(true);
        }
      }
    }
  };

  return (
    <>
      <div className="container mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <h1 className="is-size-3 font-rubik mb-2">{t("Register")}</h1>
              <p className="mb-2">
                {t(
                  "You are registering for a completely free version of RiskScribe. Some functions are limited to the paid Plans which can be bought from your user menu."
                )}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label" htmlFor="email">
                    {t("Email")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="text" id="email" autoFocus />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="password">
                    {t("Password")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="password" id="password" />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="passwordConfirm">
                    {t("Password confirmation")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="password" id="passwordConfirm" />
                  </div>
                </div>

                <div className="field is-flex is-justify-content-center">
                  <div className="control">
                    <button className={`button is-primary ${isLoading ? "is-loading" : ""}`}>{t("Register")}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Warning show={registerError} onConfirm={() => navigate("/")} title={t("Error")} content={t("Unable to process your request, please try again")} />
      <Warning show={passwordsDontMatch} onConfirm={() => setPasswordsDontMatch(false)} title={t("Error")} content={t("Passwords do not match")} />
      <Warning show={invalidEmail} onConfirm={() => setInvalidEmail(false)} title={t("Error")} content={t("Email is invalid")} />
      <Warning
        show={hasRegistered}
        onConfirm={() => navigate("/")}
        title={t("Notice")}
        content={t("User successfully registered, please follow the instructions in the email you will receive")}
      />
    </>
  );
};

export default Register;
