import React from "react";
import { useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { t } from "../services/i18n";

const LicenseResults = ({ success, onLogout }) => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    onLogout();
    navigate("/");
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <>
      <Warning
        show={success}
        onConfirm={handleSuccess}
        title={t("Notice")}
        content={t("Your license purchase was processed correctly. Please login again to start using it.")}
      />

      <Warning show={!success} onConfirm={handleCancel} title={t("Notice")} content={t("Your purchase of a new license has been cancelled.")} />
    </>
  );
};

export default LicenseResults;
