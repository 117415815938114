import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { reset } from "../services/RiskScribeSDK";
import { languageConfiguration, t } from "../services/i18n";

const Reset = ({ onResetSuccess }) => {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetError, setResetError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const password = document.getElementById("password").value;
    const passwordConfirm = document.getElementById("passwordConfirm").value;

    if (!password.length || password !== passwordConfirm) {
      setIsLoading(false);
      setPasswordsDontMatch(true);
    } else {
      try {
        await reset(params.get("code"), password);
        setIsLoading(false);
        setPasswordChanged(true);
      } catch (error) {
        setIsLoading(false);
        setResetError(true);
      }
    }
  };

  useEffect(() => {
    if (params.get("code") == null) {
      navigate("/");
    } else {
      languageConfiguration(navigator.language.slice(0, 2));
    }
  });

  return (
    <>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <h1 className="is-size-3 font-rubik mb-2">{t("Enter new password")}</h1>
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label" htmlFor="password">
                    {t("Password")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="password" id="password" autoFocus />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="passwordConfirm">
                    {t("Password confirmation")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="password" id="passwordConfirm" />
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <button type="submit" className={`button is-primary ${isLoading ? "is-loading" : ""}`}>
                      {t("OK")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Warning show={passwordsDontMatch} onConfirm={() => setPasswordsDontMatch(false)} title={t("Error")} content={t("Passwords do not match")} />
      <Warning
        show={passwordChanged}
        onConfirm={() => {
          setPasswordChanged(false);
          onResetSuccess();
          navigate("/login");
        }}
        title={t("Notice")}
        content={t("Password successfuly updated, please log in again")}
      />
      <Warning show={resetError} onConfirm={() => navigate("/")} title={t("Error")} content={t("Unable to process your request, please try again")} />
    </>
  );
};

export default Reset;
