import React, { useEffect, useState } from "react";

import Tippy from "@tippyjs/react";

import Input from "./Input";
import Warning from "./Warning";

import { t } from "../services/i18n";

const ControlsPanel = ({ objective, risk, isEditable, addControl, editControl, deleteControl, highlightSignal }) => {
  const [highlightedControl, setHighlightedControl] = useState(null);
  const [showNewInput, setShowNewInput] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [shouldReload, setShouldReload] = useState(null);

  useEffect(() => {
    const reloadControl = risk?.controls.find((control) => control.description === highlightedControl?.description || control.description === shouldReload);

    if (!reloadControl) {
      setHighlightedControl(null);
    } else {
      setHighlightedControl(reloadControl);
    }

    setShouldReload(null);
  }, [risk]);

  useEffect(() => {
    setHighlightedControl(null);
  }, [highlightSignal]);

  const handleNewControl = (controlDescription) => {
    if (controlDescription != null && !risk.controls.some((c) => c.description === controlDescription)) {
      addControl(objective, risk, controlDescription);
    }
    setShowNewInput(false);
  };

  const handleEditControl = (newDescription) => {
    if (newDescription != null && !risk.controls.some((c) => c.description === newDescription)) {
      setShouldReload(newDescription);
      editControl(objective, risk, highlightedControl, newDescription);
    }
    setShowEditInput(false);
  };

  const handleDeleteConfirm = () => {
    if (highlightedControl) {
      deleteControl(objective, risk, highlightedControl);
    }
    setShowDeleteWarning(false);
  };

  return (
    <div className="controls-panel">
      <div className="header">
        <span className="icon-text">
          <span className="is-size-3 font-rubik mb-3">{t("Controls")}</span>
          {risk && isEditable && (
            <Tippy theme="riskscribe" content={t("Add new control")} placement="right" delay={[1000, 0]}>
              <span className="icon is-size-4 has-text-link is-clickable ml-3">
                <i className="fa fa-plus-circle" onClick={() => setShowNewInput(true)}></i>
              </span>
            </Tippy>
          )}
        </span>
      </div>

      {risk && (
        <div className="content">
          {risk.controls.map((control, idx) => (
            <div
              className={`list-items ${control === highlightedControl ? "highlight" : "is-clickable"}`}
              key={idx}
              onClick={() => {
                setHighlightedControl(control);
              }}
            >
              <span>{control.description}</span>
              {isEditable && (
                <Tippy theme="riskscribe" content={t("Edit control")} delay={[1000, 0]}>
                  <span className={`icon ${control === highlightedControl ? "is-clickable" : "is-hidden"}`}>
                    <i className="fa fa-edit" onClick={() => setShowEditInput(true)}></i>
                  </span>
                </Tippy>
              )}
              {isEditable && (
                <Tippy theme="riskscribe" content={t("Delete control")} delay={[1000, 0]}>
                  <span className={`icon ${control === highlightedControl ? "is-clickable" : "is-hidden"}`}>
                    <i className="fa fa-trash" onClick={() => setShowDeleteWarning(true)}></i>
                  </span>
                </Tippy>
              )}
            </div>
          ))}
        </div>
      )}

      {showNewInput && <Input title={t("Add new control")} onConfirm={handleNewControl} initialValue="" />}

      {showEditInput && <Input title={t("Edit control")} onConfirm={handleEditControl} initialValue={highlightedControl.description} />}

      <Warning
        show={showDeleteWarning}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setShowDeleteWarning(false)}
        title={t("Confirmation")}
        content={t("The selected control will be deleted")}
      />
    </div>
  );
};

export default ControlsPanel;
