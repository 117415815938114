import React, { useState, useEffect } from "react";

import Tippy from "@tippyjs/react";

import Input from "./Input";
import Loader from "./Loader";
import Warning from "./Warning";

import { t } from "../services/i18n";

const RisksPanel = ({ objective, isEditable, selectRisk, highlightSignal, addRisk, addRisksAI, editRisk, deleteRisk }) => {
  const [highlightedRisk, setHighlightedRisk] = useState(null);
  const [showNewInput, setShowNewInput] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAIWarning, setShowAIWarning] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [shouldReload, setShouldReload] = useState(null);

  useEffect(() => {
    const reloadRisk = objective?.risks.find((risk) => risk.description === highlightedRisk?.description || risk.description === shouldReload);

    if (!reloadRisk) {
      setHighlightedRisk(null);
    } else {
      setHighlightedRisk(reloadRisk);
    }

    setShouldReload(null);
  }, [objective]);

  useEffect(() => {
    setHighlightedRisk(null);
  }, [highlightSignal]);

  const handleNewRisk = (riskDescription) => {
    if (riskDescription != null && !objective.risks.some((r) => r.description === riskDescription)) {
      addRisk(objective, riskDescription);
    }
    setShowNewInput(false);
  };

  const handleRisksAI = async () => {
    setShowAIWarning(false);
    setIsLoading(true);
    await addRisksAI(objective);
    setIsLoading(false);
  };

  const handleEditRisk = (newDescription) => {
    if (newDescription != null && !objective.risks.some((r) => r.description === newDescription)) {
      setShouldReload(newDescription);
      editRisk(
        objective,
        highlightedRisk,
        newDescription,
        highlightedRisk.inherent_probability,
        highlightedRisk.inherent_impact,
        highlightedRisk.residual_probability,
        highlightedRisk.residual_impact
      );
    }
    setShowEditInput(false);
  };

  const handleDeleteConfirm = () => {
    if (highlightedRisk) {
      deleteRisk(objective, highlightedRisk);
    }
    setShowDeleteWarning(false);
  };

  return (
    <div className="risks-list-panel">
      <div className="header">
        <span className="icon-text">
          <span className="is-size-3 font-rubik mb-3">{t("Risks")}</span>
          {objective && isEditable && (
            <Tippy theme="riskscribe" content={t("Add new risk")} placement="right" delay={[1000, 0]}>
              <span className="icon is-size-4 has-text-link is-clickable ml-3">
                <i className="fa fa-plus-circle" onClick={() => setShowNewInput(true)}></i>
              </span>
            </Tippy>
          )}
          {objective?.risks?.length === 0 && isEditable && (
            <Tippy theme="riskscribe" content={t("Add risks automatically")} placement="right" delay={[1000, 0]}>
              <span className="icon is-size-4 has-text-primary is-clickable ml-3">
                <i className="fas fa-robot" onClick={() => setShowAIWarning(true)}></i>
              </span>
            </Tippy>
          )}
        </span>
      </div>

      {objective && (
        <div className="content">
          {objective.risks.map((risk, idx) => (
            <div
              className={`list-items ${risk === highlightedRisk ? "highlight" : "is-clickable"}`}
              key={idx}
              onClick={() => {
                setHighlightedRisk(risk);
                selectRisk(risk);
              }}
            >
              <span>{risk.description}</span>
              {isEditable && (
                <Tippy theme="riskscribe" content={t("Edit risk")} delay={[1000, 0]}>
                  <span className={`icon ${risk === highlightedRisk ? "is-clickable" : "is-hidden"}`}>
                    <i className="fa fa-edit" onClick={() => setShowEditInput(true)}></i>
                  </span>
                </Tippy>
              )}
              {isEditable && (
                <Tippy theme="riskscribe" content={t("Delete risk")} delay={[1000, 0]}>
                  <span className={`icon ${risk === highlightedRisk ? "is-clickable" : "is-hidden"}`}>
                    <i className="fa fa-trash" onClick={() => setShowDeleteWarning(true)}></i>
                  </span>
                </Tippy>
              )}
              <Tippy theme="riskscribe" content={t("Risk has not been evaluated")} delay={[1000, 0]}>
                <span
                  className={`icon ${
                    risk.inherent_impact === 1 && risk.inherent_probability === 1 && risk.residual_probability === 1 && risk.residual_impact === 1
                      ? "has-text-primary"
                      : "is-hidden"
                  }`}
                >
                  <i className="fa fa-exclamation-triangle"></i>
                </span>
              </Tippy>
            </div>
          ))}
        </div>
      )}

      {showNewInput && <Input title={t("Add new risk")} onConfirm={handleNewRisk} initialValue="" />}

      {showEditInput && <Input title={t("Edit risk")} onConfirm={handleEditRisk} initialValue={highlightedRisk.description} />}

      <Loader loading={isLoading} />

      <Warning
        show={showAIWarning}
        onConfirm={handleRisksAI}
        onCancel={() => setShowAIWarning(false)}
        title={t("Warning")}
        content={t(
          "Generating a list of risks using Artificial Intelligence is a valuable help but the results should be carefully analyzed to ensure they are adequate. Please be aware that usually you will need to add, modify or remove some risks until the final list is correct."
        )}
      />
      <Warning
        show={showDeleteWarning}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setShowDeleteWarning(false)}
        title={t("Confirmation")}
        content={t("The selected risk will be deleted")}
      />
    </div>
  );
};

export default RisksPanel;
