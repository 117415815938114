import React from "react";

const HeatMapPreview = ({ criteria }) => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
  };

  const matrixStyle = {
    width: "100%",
    display: "grid",
    gridTemplateColumns: `repeat(${criteria.probability.total}, 1fr)`,
    gridTemplateRows: `repeat(${criteria.impact.total}, 1fr)`,
  };

  let cells = [];

  for (let p = criteria.probability.total - 1; p >= 0; p--) {
    for (let i = 0; i < criteria.impact.total; i++) {
      let exposure = ((p + 1) * criteria.exposure.probability) / 100 + ((i + 1) * criteria.exposure.impact) / 100;
      exposure = Math.round(exposure * 10) / 10;
      let exposuretHeat = exposure < criteria.tolerance.caution ? "is-success" : exposure >= criteria.tolerance.unacceptable ? "is-danger" : "is-warning";

      cells.push(
        <div key={100 * i + p} className={`tag m-1 ${exposuretHeat}`}>
          {exposure}
        </div>
      );
    }
  }

  return (
    <div style={containerStyle}>
      <div style={matrixStyle}>{cells}</div>
    </div>
  );
};

export default HeatMapPreview;
