import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { login, requestReset } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

const Login = ({ onLoginSuccess, onLogout }) => {
  const navigate = useNavigate();

  const [isForget, setIsForget] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onLogout();
  }, []);

  const forgotPassword = () => {
    setIsForget(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (isForget) {
      const email = document.getElementById("email").value;

      try {
        let url = window.location.origin;
        if (window.location.pathname === "/app/login") {
          url += "/app";
        }

        await requestReset(email, url);
        setIsLoading(false);
        setResetSent(true);
      } catch (error) {
        console.dir(error);
        setIsLoading(false);
        setResetError(true);
      }
    } else {
      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;
      const rememberMe = document.getElementById("rememberMe").checked;

      try {
        const loginData = await login(email, password, rememberMe);
        onLoginSuccess(loginData);
        setIsLoading(false);
        navigate("/");
      } catch (error) {
        setIsLoading(false);
        setInvalidCredentials(true);
      }
    }
  };

  return (
    <>
      <div className="container mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <h1 className="is-size-3 font-rubik mb-2">{isForget ? t("Request password reset") : t("Login")}</h1>
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label" htmlFor="email">
                    {t("Email")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="text" id="email" autoFocus />
                  </div>
                </div>

                {isForget && (
                  <>
                    <div className="field">
                      <div className="control">
                        <button type="submit" className={`button is-primary is-fullwidth ${isLoading ? "is-loading" : ""}`}>
                          {t("Request password reset")}
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {!isForget && (
                  <>
                    <div className="field">
                      <label className="label" htmlFor="password">
                        {t("Password")}:
                      </label>
                      <div className="control">
                        <input className="input is-primary" type="password" id="password" />
                      </div>
                    </div>

                    <div className="field has-text-centered">
                      <div className="control">
                        <input type="checkbox" id="rememberMe" />
                        <label className="checkbox ml-2 is-size-6" htmlFor="rememberMe">
                          {t("Remember me?")}
                        </label>
                      </div>
                    </div>

                    <div className="field is-flex is-justify-content-center">
                      <div className="control">
                        <button type="submit" className={`button is-primary ${isLoading ? "is-loading" : ""}`}>
                          {t("Log in")}
                        </button>
                      </div>
                    </div>

                    <div className="has-text-centered is-size-7">
                      <span className="is-clickable is-underlined" onClick={forgotPassword}>
                        {t("Forgot your password?")}
                      </span>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <Warning show={invalidCredentials} onConfirm={() => setInvalidCredentials(false)} title={t("Error")} content={t("Invalid user or password")} />
      <Warning
        show={resetSent}
        onConfirm={() => navigate("/")}
        title={t("Notice")}
        content={t("User successfully reset, please follow the instructions in the email you will receive")}
      />
      <Warning show={resetError} onConfirm={() => navigate("/")} title={t("Error")} content={t("Unable to process your request, please try again")} />
    </>
  );
};

export default Login;
