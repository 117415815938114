import React, { useEffect } from "react";

import Tippy from "@tippyjs/react";

import { t } from "../services/i18n";

const ReportSelector = ({ flags, updateFlags, onClose, onCancel }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }

      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        onCancel();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head has-text-centered">
          <span className="modal-card-title font-rubik">{t("Report components")}</span>
        </header>
        <section className="modal-card-body report-selector-container">
          <div className="is-size-6 mb-3">
            <span>{t("Risk summary")}</span>
            <span className="icon is-size-7">
              <Tippy theme="riskscribe" content={t("Complete list of all risks in the assessment")}>
                <i className="fa fa-question-circle"></i>
              </Tippy>
            </span>
          </div>
          <div className="is-size-7 has-text-centered mb-3">
            <input type="checkbox" checked={flags.summary} onChange={() => updateFlags((prev) => ({ ...prev, summary: !flags.summary }))} />
          </div>
          <div className="is-size-6 mb-3">
            <span>{t("Action plan")}</span>
            <span className="icon is-size-7">
              <Tippy theme="riskscribe" content={t("All actions included in the Action plan")}>
                <i className="fa fa-question-circle"></i>
              </Tippy>
            </span>
          </div>
          <div className="is-size-7 has-text-centered mb-3">
            <input type="checkbox" checked={flags.plan} onChange={() => updateFlags((prev) => ({ ...prev, plan: !flags.plan }))} />
          </div>
          <div className="is-size-6 mb-3">
            <span>{t("Annex - Main risks")}</span>
            <span className="icon is-size-7">
              <Tippy theme="riskscribe" content={t("List of the 10 risks with highest residual exposure")}>
                <i className="fa fa-question-circle"></i>
              </Tippy>
            </span>
          </div>
          <div className="is-size-7 has-text-centered mb-3">
            <input type="checkbox" checked={flags.risk_annex} onChange={() => updateFlags((prev) => ({ ...prev, risk_annex: !flags.risk_annex }))} />
          </div>
          <div className="is-size-6 mb-3">
            <span>{t("Annex - Critical controls")}</span>
            <span className="icon is-size-7">
              <Tippy theme="riskscribe" content={t("Controls that mitigate unacceptable inherent exposures and whose effectiveness must be monitored")}>
                <i className="fa fa-question-circle"></i>
              </Tippy>
            </span>
          </div>
          <div className="is-size-7 has-text-centered mb-3">
            <input type="checkbox" checked={flags.control_annex} onChange={() => updateFlags((prev) => ({ ...prev, control_annex: !flags.control_annex }))} />
          </div>
          <div className="is-size-6 mb-3">
            <span>{t("Annex - Risk assessment criteria")}</span>
            <span className="icon is-size-7">
              <Tippy theme="riskscribe" content={t("Probability and impact tables, exposure formula and tolerance limits used in the assessment")}>
                <i className="fa fa-question-circle"></i>
              </Tippy>
            </span>
          </div>
          <div className="is-size-7 has-text-centered mb-3">
            <input
              type="checkbox"
              checked={flags.criteria_annex}
              onChange={() => updateFlags((prev) => ({ ...prev, criteria_annex: !flags.criteria_annex }))}
            />
          </div>
        </section>
        <footer className="modal-card-foot has-background-white is-flex is-justify-content-space-evenly">
          <button className="button is-link" onClick={onCancel}>
            {t("Cancel")}
          </button>
          <button className={"button is-primary"} onClick={onClose}>
            {t("OK")}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ReportSelector;
