export const internalCriteria = (language) => {
  switch (language) {
    case "en":
    default:
      return [
        {
          name: "Standard (Company)",
          probability: {
            total: 5,
            names: ["Very Low", "Low", "Medium", "High", "Very High"],
            descriptions: [
              "<5% expected chance / unprecedented or virtually no known occurrences",
              "5-10% expected chance / very rare, few instances historically",
              "11-50% expected chance / a mixed record of occurrences",
              "51-75% expected chance / regularly observed, often enough to be a concern",
              ">75% expected chance / events are a common occurrence, nearly predictable",
            ],
          },
          impact: {
            total: 5,
            names: ["Very Low", "Low", "Medium", "High", "Very High"],
            descriptions: [
              "Insignificant financial deviation (<1%) / unnoticed media mention / standard regulatory adherence / minor first-aid incidents / negligible data discrepancies without service effect / almost imperceptible service quality dip",
              "Small financial loss (1-5%) / brief negative media or social mention / minor non-compliance with easy fixes / onsite-treatable injuries / minimal data loss with no client impact / short-term client service hiccups",
              "Moderate financial impact (5-10%) / medium-term negative media exposure / moderate fines or disruptions due to regulatory issues / offsite medical care with some work absence / manageable data recovery efforts / noticeable client service delays with some complaints",
              "Major financial hit (10-25%) / sustained negative publicity affecting stakeholder views / significant regulatory fines or operational changes / serious injuries leading to substantial work stoppage / large-scale data loss with operational impact / extensive client service disruptions leading to dissatisfaction",
              "Critical financial loss (>25%) / severe, long-lasting reputational damage with market trust erosion / major regulatory violations causing extensive operational shutdown / catastrophic injuries or fatalities / massive and irrecoverable data breaches / complete service failure resulting in client abandonment",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 2.4,
            unacceptable: 3.4,
          },
        },
        {
          name: "Standard (Person)",
          probability: {
            total: 5,
            names: ["Very Low", "Low", "Medium", "High", "Very High"],
            descriptions: [
              "Almost never happens / may have never been personally experienced",
              "Rare, might have been experienced once or known from close contacts",
              "Occurs from time to time / known to happen to oneself or acquaintances",
              "Happens regularly / a familiar event in one’s own life or immediate circle",
              "A part of normal life / happens very frequently or is expected",
            ],
          },
          impact: {
            total: 5,
            names: ["Very Low", "Low", "Medium", "High", "Very High"],
            descriptions: [
              "Inconsequential expenses well within budget / negligible rumors with no real credibility / normal day-to-day disagreements without lasting upset / minor health issues with no medical attention required / trivial legal questions, easily dismissed / hardly noticeable workplace feedback",
              "Minor financial setbacks, easily recovered / short-lived gossip or social media embarrassments / commonplace family disputes, quickly forgotten / small health issues, treated with over-the-counter medicine / straightforward legal issues, resolved without significant time or cost / brief stints of work dissatisfaction or minor challenges",
              "Financial challenges requiring some lifestyle adjustments / reputational issues that may require explanation / family or relationship tensions that need addressing / medical conditions that disrupt daily activities but are not long-term / legal issues that require professional advice but no major litigation / challenges at work that may affect job satisfaction or short-term prospects",
              "Significant financial loss, possibly necessitating loans or lifestyle downgrades / public personal embarrassments or reputational hits that need strategic management / serious relationship or family issues requiring counseling or mediation / health issues that necessitate medical intervention and significant recovery time / legal problems that involve court appearances and potential fines / substantial career hurdles, like demotion or threats to job security",
              "Severe financial crisis, threatening stability or leading to bankruptcy / major scandals or reputational crises with lasting effects / deep family rifts or relationship breakdowns that may have legal or social repercussions / critical health emergencies or injuries requiring hospitalization or causing permanent disability / major legal battles with severe consequences / career-ending situations or prolonged unemployment",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 2.4,
            unacceptable: 3.4,
          },
        },
        {
          name: "Simple (Company)",
          probability: {
            total: 3,
            names: ["Low", "Medium", "High"],
            descriptions: [
              "<10% expected chance / no occurrences in the last 5+ years",
              "10-50% expected chance / several occurrences over multiple years",
              ">50% expected chance / frequent occurrences, likely annually or more often",
            ],
          },
          impact: {
            total: 3,
            names: ["Low", "Medium", "High"],
            descriptions: [
              "Minor cost overruns or financial loss / short-lived negative attention in media or social networks / simple regulatory fixes with no serious penalties / minor injuries treatable onsite / trivial data loss without operational effect / brief client service issues with quick resolution",
              "Noticeable financial adjustments or loss prompting budget review / negative media coverage or social media backlash requiring PR campaigns / regulatory fines or temporary operational disruptions / injuries necessitating offsite medical care and causing work absence / data issues demanding restoration with minor operational impact / client service disruptions leading to active complaints and need for remediation",
              "Substantial financial downturn impacting overall stability / widespread reputational damage with lasting trust issues among stakeholders / serious regulatory violations resulting in heavy fines and major operational halts / critical injuries leading to long-term disability or fatalities / extensive data breaches causing severe operational and reputational harm / profound service failures resulting in major client defections and contract breaches",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 1.8,
            unacceptable: 2.4,
          },
        },
        {
          name: "Simple (Person)",
          probability: {
            total: 3,
            names: ["Low", "Medium", "High"],
            descriptions: [
              "Seldom to never expected / hasn't happened in recent memory",
              "Could happen occasionally / has happened a few times in one's life",
              "Expected to happen regularly / common experiences or routinely anticipated events",
            ],
          },
          impact: {
            total: 3,
            names: ["Low", "Medium", "High"],
            descriptions: [
              "Manageable expenses / temporary reputational rumors / minor family or relationshipt disagreements / basic medical care / simple legal formalities / slight career challenges",
              "Budget-affecting financial loss / reputational issues in social or work circles / notable family or relationship tensions / medical conditions affecting routine / moderate legal concerns / career growth barriers",
              "Major financial crisis or debt / widespread reputational damage / serious family or relationship conflicts / severe medical emergencies or injuries / significant legal actions / major career setbacks or job loss",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 1.8,
            unacceptable: 2.4,
          },
        },
      ];
    case "es":
      return [
        {
          name: "Estándar (Compañía)",
          probability: {
            total: 5,
            names: ["Muy baja", "Baja", "Media", "Alta", "Muy alta"],
            descriptions: [
              "<5% probabilidad esperada / sin precedentes o casi sin ocurrencias conocidas",
              "5-10% probabilidad esperada / muy raras, pocas instancias históricas",
              "11-50% probabilidad esperada / ocurrencias esporádicas",
              "51-75% probabilidad esperada / observadas regularmente, con suficiente frecuencia como para ser preocupantes",
              ">75% probabilidad esperada / eventos son ocurrencias comunes, casi predecibles",
            ],
          },
          impact: {
            total: 5,
            names: ["Muy bajo", "Bajo", "Medio", "Alto", "Muy alto"],
            descriptions: [
              "Desviación financiera insignificante (<1%) / mención en medios desapercibida / cumplimiento regulatorio estándar / incidentes menores de primeros auxilios / discrepancias de datos despreciables sin efecto en el servicio / disminución casi imperceptible en la calidad del servicio",
              "Pequeña pérdida financiera (1-5%) / mención negativa breve en medios o redes sociales / incumplimiento menor con soluciones fáciles / lesiones tratables en el sitio / pérdida de datos mínima sin impacto en el cliente / contratiempos breves en el servicio al cliente",
              "Impacto financiero moderado (5-10%) / exposición mediática negativa de término medio / multas moderadas o interrupciones por cuestiones regulatorias / atención médica externa con alguna ausencia laboral / esfuerzos de recuperación de datos manejables / retrasos notorios en el servicio al cliente con algunas quejas",
              "Golpe financiero importante (10-25%) / publicidad negativa sostenida que afecta la percepción de los interesados / multas regulatorias significativas o cambios operativos / lesiones graves que llevan a un paro de trabajo sustancial / pérdida de datos a gran escala con impacto operativo / interrupciones extensas en el servicio al cliente que llevan a la insatisfacción",
              "Pérdida financiera crítica (>25%) / daño reputacional grave y duradero con erosión de la confianza del mercado / violaciones regulatorias mayores que causan cierre operativo extenso / lesiones catastróficas o fatalidades / brechas de datos masivas e irrecuperables / fallo completo del servicio que resulta en abandono del cliente",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 2.4,
            unacceptable: 3.4,
          },
        },
        {
          name: "Estándar (Persona)",
          probability: {
            total: 5,
            names: ["Muy baja", "Baja", "Media", "Alta", "Muy alta"],
            descriptions: [
              "Casi nunca ocurre / puede que nunca se haya experimentado personalmente",
              "Raro, podría haberse experimentado una vez o conocido por contactos cercanos",
              "Ocurre de vez en cuando / conocido por suceder a uno mismo o a personas cercanas",
              "Ocurre regularmente / un evento familiar en la vida de uno o en el círculo inmediato",
              "Parte de la vida normal / ocurre muy frecuentemente o se espera",
            ],
          },
          impact: {
            total: 5,
            names: ["Muy bajo", "Bajo", "Medio", "Alto", "Muy alto"],
            descriptions: [
              "Gastos insignificantes dentro del presupuesto / rumores sin credibilidad / desacuerdos cotidianos sin consecuencias duraderas / problemas de salud menores sin atención médica requerida / cuestiones legales triviales, fácilmente desestimables / comentarios en el trabajo apenas perceptibles",
              "Contratiempos financieros menores, fácilmente superables / chismes o vergüenzas en redes sociales de corta duración / disputas familiares comunes, rápidamente olvidadas / pequeñas afecciones de salud, tratadas con medicina sin receta / asuntos legales sencillos, resueltos sin tiempo ni costo significativos / períodos breves de insatisfacción laboral o desafíos menores",
              "Desafíos financieros que requieren ajustes en el estilo de vida / problemas de reputación que pueden requerir una explicación / tensiones en relaciones o familia que necesitan atención / condiciones médicas que interrumpen las actividades diarias pero no a largo plazo / problemas legales que requieren asesoría profesional pero sin litigio mayor / desafíos en el trabajo que pueden afectar la satisfacción laboral o las perspectivas a corto plazo",
              "Pérdida financiera significativa, posiblemente necesitando préstamos o reducción del nivel de vida / vergüenzas personales públicas o daños a la reputación que necesitan gestión estratégica / problemas serios de relación o familia que requieren asesoramiento o mediación / problemas de salud que necesitan intervención médica y tiempo de recuperación importante / problemas legales que involucran comparecencias en corte y posibles multas / obstáculos profesionales considerables, como la democión o amenazas a la seguridad laboral",
              "Crisis financiera grave, amenazando la estabilidad o conduciendo a la bancarrota / escándalos mayores o crisis de reputación con efectos duraderos / rupturas familiares profundas o desintegraciones de relaciones que pueden tener repercusiones legales o sociales / emergencias de salud críticas o lesiones que requieren hospitalización o causan discapacidad permanente / batallas legales mayores con consecuencias graves / situaciones que terminan la carrera profesional o desempleo prolongado",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 2.4,
            unacceptable: 3.4,
          },
        },
        {
          name: "Simple (Compañía)",
          probability: {
            total: 3,
            names: ["Baja", "Media", "Alta"],
            descriptions: [
              "<10% probabilidad esperada / sin ocurrencias en los últimos 5+ años",
              "10-50% probabilidad esperada / varias ocurrencias a lo largo de varios años",
              ">50% probabilidad esperada / ocurrencias frecuentes, probablemente anuales o más a menudo",
            ],
          },
          impact: {
            total: 3,
            names: ["Bajo", "Medio", "Alto"],
            descriptions: [
              "Excedentes de costos menores o pérdidas financieras insignificantes / atención negativa breve en medios o redes sociales / soluciones regulatorias sencillas sin sanciones graves / lesiones menores tratables in situ / pérdida de datos trivial sin efecto operativo / problemas de servicio al cliente breves con resolución rápida",
              "Ajustes financieros notables o pérdidas que requieren revisión presupuestaria / cobertura mediática negativa o reacción adversa en redes sociales que necesitan campañas de RR.PP. / multas regulatorias o interrupciones operativas temporales / lesiones que requieren atención médica externa y causan ausencia laboral / problemas de datos que exigen restauración con impacto operativo menor / interrupciones en el servicio al cliente que llevan a quejas activas y necesidad de remedios",
              "Contratiempos financieros considerables que afectan la estabilidad general / daño reputacional amplio con problemas de confianza duraderos entre las partes interesadas / violaciones regulatorias graves que resultan en multas elevadas y paros operativos mayores / lesiones críticas que conducen a discapacidad a largo plazo o fatalidades / brechas de datos extensas que causan daño operativo y reputacional severo / fallos de servicio profundos que resultan en deserciones importantes de clientes y rupturas de contratos",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 1.8,
            unacceptable: 2.4,
          },
        },
        {
          name: "Simple (Persona)",
          probability: {
            total: 3,
            names: ["Baja", "Media", "Alta"],
            descriptions: [
              "Raramente o nunca se espera / no ha sucedido en la memoria reciente",
              "Podría ocurrir ocasionalmente / ha sucedido algunas veces en la vida",
              "Se espera que ocurra regularmente / experiencias comunes o eventos rutinariamente anticipados",
            ],
          },
          impact: {
            total: 3,
            names: ["Bajo", "Medio", "Alto"],
            descriptions: [
              "Gastos manejables / rumores temporales que afectan la reputación / desacuerdos menores familiares o de pareja / atención médica básica / formalidades legales simples / desafíos leves en la carrera profesional",
              "Pérdidas financieras que afectan el presupuesto / problemas de reputación en círculos sociales o laborales / tensiones familiares o de pareja notables / condiciones médicas que afectan la rutina / preocupaciones legales moderadas / barreras en el crecimiento profesional",
              "Crisis financiera mayor o deudas significativas / daño reputacional amplio / conflictos serios familiares o de pareja / emergencias médicas graves o lesiones severas / acciones legales importantes / contratiempos mayores en la carrera profesional o pérdida de empleo",
            ],
          },
          exposure: {
            probability: 40.0,
            impact: 60.0,
          },
          tolerance: {
            caution: 1.8,
            unacceptable: 2.4,
          },
        },
      ];
  }
};

export const availableTemplates = (language) => {
  switch (language) {
    case "en":
    default:
      return ["ISO 9001 (English)", "ISO 14001 (English)", "ISO 27001 (English)", "ISO 45001 (English)"];
    case "es":
      return ["ISO 9001 (Español)", "ISO 14001 (Español)", "ISO 27001 (Español)", "ISO 45001 (Español)"];
  }
};
