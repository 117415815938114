import React from "react";

import { SpinnerDiamond } from "spinners-react";

import { t } from "../services/i18n";

const Loader = ({ loading }) => {
  return (
    <div className={`modal ${loading ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box has-text-centered">
          <SpinnerDiamond size={100} thickness={150} speed={100} color="rgba(103, 58, 183, 1)" secondaryColor="rgba(103, 58, 183, 0.5)" />
          <p className="mt-5">{t("Please wait - the process can take a few seconds")}</p>
        </div>
      </div>
    </div>
  );
};

export default Loader;
