import React, { useState, useEffect } from "react";

import Tippy from "@tippyjs/react";

const CustomDropdown = ({ isEditable, names, descriptions, values, onChange, initialValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue || values[0]);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div
      className={`custom-dropdown ${isEditable ? "is-clickable" : ""}`}
      onClick={() => {
        if (isEditable) setIsOpen(!isOpen);
      }}
      onMouseLeave={() => {
        if (isOpen) setIsOpen(false);
      }}
    >
      <div className="custom-dropdown-selected-option">{names[values.indexOf(selectedValue)]}</div>
      {isOpen && (
        <div className="custom-dropdown-options-container">
          {names.map((name, index) =>
            descriptions[index] ? (
              <Tippy key={index} theme="riskscribe" content={descriptions[index]} placement="right">
                <div className="custom-dropdown-option" onClick={() => handleOptionClick(values[index])}>
                  {name}
                </div>
              </Tippy>
            ) : (
              <div className="custom-dropdown-option" key={index} onClick={() => handleOptionClick(values[index])}>
                {name}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
