import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Tippy from "@tippyjs/react";

import ACLEditor from "./ACLEditor";
import Loader from "./Loader";
import NewAssessment from "./NewAssessment";
import ReportSelector from "./ReportSelector";
import Warning from "./Warning";

import { getReport, deleteAssessment } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

const AssessmentList = ({
  assessments,
  selectAssessment,
  updateAssessments,
  user,
  customCriteria,
  tier,
  language,
  shouldStartNewAssessment,
  setShouldStartNewAssessment,
}) => {
  const navigate = useNavigate();

  const [editAcl, setEditAcl] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [title, setTitle] = useState(null);
  const [period, setPeriod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showReportSelector, setShowReportSelector] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);
  const [reportFlags, setReportFlags] = useState({
    summary: true,
    plan: true,
    risk_annex: true,
    control_annex: true,
    criteria_annex: true,
  });
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const handleEditACL = (id) => {
    setAssessmentId(id);
    setEditAcl(true);
  };

  const handleDelete = (id) => {
    setAssessmentId(id);
    setShowDeleteWarning(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteAssessment(assessmentId);
      setShowDeleteWarning(false);
      updateAssessments((prev) => prev.filter((a) => a.id !== assessmentId));
    } catch (error) {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    }
  };

  const handleReport = (id, title, period) => {
    setAssessmentId(id);
    setTitle(title);
    setPeriod(period);

    if (tier < 2) {
      generateReport(id, title, period);
    } else {
      setShowReportSelector(true);
    }
  };

  const generateReport = async (providedId, providedTitle, providedPeriod) => {
    let localId = providedId !== undefined ? providedId : assessmentId;
    let localTitle = providedTitle !== undefined ? providedTitle : title;
    let localPeriod = providedPeriod !== undefined ? providedPeriod : period;

    setShowReportSelector(false);

    try {
      setIsLoading(true);
      let report = await getReport(localId, reportFlags);
      setIsLoading(false);

      const url = URL.createObjectURL(report);
      const link = document.createElement("a");
      link.href = url;
      link.download = `RiskScribe - ${localTitle} (${localPeriod})`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      setIsLoading(false);
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    }
  };

  return (
    <>
      <div className="assessment-list px-4">
        {assessments?.map((assessmentData, idx) => (
          <div className="assessment-list-item" key={idx}>
            <div
              className="assessment-list-item-description is-clickable"
              onClick={() => {
                selectAssessment(assessmentData);
                navigate("/assessment");
              }}
            >
              <div className="is-size-2 font-rubik">{assessmentData.title}</div>
              <div className="is-size-6">{assessmentData.period}</div>
            </div>

            <button
              disabled={(assessmentData.permissions & 130) === 0 || tier < 3}
              className="button is-primary is-small mb-2"
              onClick={() => handleEditACL(assessmentData.id)}
            >
              <Tippy content={t("Available in the Corporate plan")} theme="riskscribe" placement="right" delay={[1000, 0]} disabled={tier === 3}>
                <span>{t("Access management")}</span>
              </Tippy>
            </button>
            <button
              disabled={(assessmentData.permissions & 132) === 0 || tier === 0}
              className="button is-primary is-small mb-2"
              onClick={() => {
                selectAssessment(assessmentData);
                navigate("/plan");
              }}
            >
              <Tippy content={t("Available in all paid plans")} theme="riskscribe" placement="right" delay={[0, 0]} disabled={tier >= 2}>
                <span>{t("Action plan")}</span>
              </Tippy>
            </button>

            <button className="button is-link is-small mb-2" onClick={() => handleReport(assessmentData.id, assessmentData.title, assessmentData.period)}>
              {t("Report")}
            </button>

            <button disabled={(assessmentData.permissions & 128) === 0} className="button is-danger is-small" onClick={() => handleDelete(assessmentData.id)}>
              {t("Delete")}
            </button>
          </div>
        ))}
        {assessments.length === 0 && (
          <div className="hero is-fullheight-with-navbar">
            <div className="hero-body has-text-centered container">
              <button className="button is-primary is-large" onClick={() => setShouldStartNewAssessment(true)}>
                {t("Start your first assessment")}
              </button>
            </div>
          </div>
        )}
      </div>

      {editAcl && <ACLEditor assessmentId={assessmentId} user={user} closeAclEdit={() => setEditAcl(false)} language={language} />}

      {showReportSelector && (
        <ReportSelector
          flags={reportFlags}
          updateFlags={setReportFlags}
          onClose={() => generateReport()}
          onCancel={() => setShowReportSelector(false)}
          language={language}
        />
      )}

      {shouldStartNewAssessment && (
        <NewAssessment
          assessments={assessments}
          customCriteria={customCriteria}
          updateAssessments={updateAssessments}
          onClose={() => setShouldStartNewAssessment(false)}
          tier={tier}
          language={language}
        />
      )}

      <Loader loading={isLoading} />

      <Warning
        show={showDeleteWarning}
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteWarning(false)}
        title={t("Confirmation")}
        content={t("The selected assessment will be deleted")}
      />

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
    </>
  );
};

export default AssessmentList;
