import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import Warning from "./Warning";

import { logout } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

import logoImage from "../images/logo-white.svg";

const Menu = ({ loggedIn, email, onLogout, language, setShouldStartNewAssessment, preventNewAssessment }) => {
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [showFreeUserMessage, setShowFreeUserMessage] = useState(false);
  const [burgerActive, setBurgerActive] = useState(false);

  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    try {
      setLogoutConfirm(false);
      await logout(email);
      onLogout();
      navigate("/");
    } catch (error) {
      onLogout();
      navigate("/");
    }
  };

  const handleNewAssessmentClick = () => {
    setBurgerActive(false);

    if (!preventNewAssessment) {
      setShouldStartNewAssessment(true);
    } else {
      setShowFreeUserMessage(true);
    }
  };
  return (
    <>
      <nav className="navbar is-primary font-rubik" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item prevent-active" to="/" onClick={() => setBurgerActive(false)}>
            <img style={{ height: "2rem", width: "auto" }} src={logoImage} alt="RiskScribe" />
          </Link>

          <a
            role="button"
            className={`navbar-burger ${burgerActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setBurgerActive(!burgerActive)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${burgerActive ? "is-active" : ""}`}>
          <div className="navbar-start"></div>
          <div className="navbar-end">
            {loggedIn ? (
              <>
                <div className="navbar-item">
                  <Link className="button new-assessment" to="/" onClick={handleNewAssessmentClick}>
                    {t("New assessment")}
                  </Link>
                </div>

                <Link className="navbar-item" to="/list" onClick={() => setBurgerActive(false)}>
                  {t("Risk assessments")}
                </Link>
                <Link className="navbar-item" to="/criteria" onClick={() => setBurgerActive(false)}>
                  {t("Evaluation criteria")}
                </Link>
                <a
                  className="navbar-item prevent-active"
                  href={language === "es" ? "https://riskscribe.com/es/guide/" : "https://riskscribe.com/en/guide/"}
                  target="_blank"
                >
                  {t("User guide")}
                </a>
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className={`navbar-link ${burgerActive ? "" : "has-text-white"}`}>{email}</a>
                  <div className="navbar-dropdown">
                    <Link className="navbar-item" to="/settings" onClick={() => setBurgerActive(false)}>
                      {t("Configuration")}
                    </Link>
                    <hr className="navbar-divider" />
                    <Link className="navbar-item" to="/license" onClick={() => setBurgerActive(false)}>
                      {t("Plan")}
                    </Link>
                    <hr className="navbar-divider" />
                    <Link className="navbar-item" to="/contact" onClick={() => setBurgerActive(false)}>
                      {t("Support")}
                    </Link>
                    <hr className="navbar-divider" />
                    <span
                      className="navbar-item is-clickable"
                      onClick={() => {
                        setBurgerActive(false);
                        setLogoutConfirm(true);
                      }}
                    >
                      {t("Log out")}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Link className="navbar-item" to="/register" onClick={() => setBurgerActive(false)}>
                  {t("Registration")}
                </Link>
                <Link className="navbar-item" to="/login" onClick={() => setBurgerActive(false)}>
                  {t("Log in")}
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>

      <Warning
        show={logoutConfirm}
        onConfirm={handleLogoutClick}
        onCancel={() => setLogoutConfirm(false)}
        title={t("Confirmation")}
        content={t("Are you sure you wish to log out?")}
      />

      <Warning
        show={showFreeUserMessage}
        onConfirm={() => setShowFreeUserMessage(false)}
        title={t("Unavailable in the free plan")}
        content={
          <div className="content">
            <p>{t("Unlock an unlimited number of assessments and AI assistant uses with any paid plan.")}</p>
            <p>{t("Depending on the plan you choose, you will also have access to premium features such as:")}</p>
            <ul>
              <li>{t("Action plans and configurable reports")}</li>
              <li>{t("Comprehensive templates for ISO 9001, 14001, 27001, and 45001")}</li>
              <li>{t("Tailored risk evaluation criteria")}</li>
              <li>{t("Collaborative tools for shared assessments with robust access controls")}</li>
            </ul>
            <div className="has-text-centered py-4">
              <a href="/license" className="is-size-6 has-text-weight-bold is-underlined">
                {t("Discover our plans and upgrade your license")}
              </a>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Menu;
