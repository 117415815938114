import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// This component should show some sort of Splash in the standalone version... or not

const Home = ({ loggedIn }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn !== null) {
      if (loggedIn) {
        navigate("/list");
      } else {
        navigate("/login");
      }
    }
  });

  return null;
};

export default Home;
