import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Tippy from "@tippyjs/react";

import Input from "./Input";
import Warning from "./Warning";

import { getAcl, updateACl, checkUserExists } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

const ACLEditor = ({ assessmentId, user, closeAclEdit }) => {
  const [acl, setAcl] = useState(null);
  const [showNewInput, setShowNewInput] = useState(false);
  const [showInvalidUser, setShowInvalidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);

  const originalAcl = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!showNewInput && !showInvalidUser) {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          handleConfirm();
        }

        if (event.key === "Escape") {
          event.preventDefault();
          event.stopPropagation();
          closeAclEdit();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [acl, showNewInput, showInvalidUser]);

  useEffect(() => {
    if (assessmentId) {
      const loadAcl = async () => {
        try {
          let aclData = await getAcl(assessmentId);
          setAcl(aclData);
          originalAcl.current = aclData;
        } catch (error) {
          if (error.name === "APIError") {
            if (error.status === 401) {
              setShowSessionExpired(true);
            } else if (error.status === 403) {
              setShowAuthorizationError(true);
            } else {
              setShowServerError(true);
            }
          } else {
            setShowNetworkProblem(true);
          }
        }
      };

      loadAcl();
    } else {
      navigate("/");
    }
  }, [assessmentId]);

  const handleNew = async (user) => {
    setShowNewInput(false);
    if (user && !acl.some((access) => access.id === user)) {
      try {
        if (await checkUserExists(user)) {
          setAcl((prev) => [...prev, { id: user, permissions: 0 }]);
        } else {
          setShowInvalidUser(true);
        }
      } catch (error) {
        if (error.name === "APIError") {
          if (error.status === 401) {
            setShowSessionExpired(true);
          } else if (error.status === 403) {
            setShowAuthorizationError(true);
          } else {
            setShowServerError(true);
          }
        } else {
          setShowNetworkProblem(true);
        }
      }
    }
  };

  const handleChange = (index, mask) => {
    setAcl((prev) => prev.map((item, idx) => (idx === index ? { id: item.id, permissions: item.permissions ^ mask } : item)));
  };

  const handleRemove = (index) => {
    setAcl((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleConfirm = async () => {
    if (JSON.stringify(acl) !== JSON.stringify(originalAcl.current)) {
      try {
        console.log("in here");
        setIsLoading(true);
        await updateACl(assessmentId, acl);
        setIsLoading(false);
        closeAclEdit();
      } catch (error) {
        setIsLoading(false);
        if (error.name === "APIError") {
          if (error.status === 401) {
            setShowSessionExpired(true);
          } else if (error.status === 403) {
            setShowAuthorizationError(true);
          } else {
            setShowServerError(true);
          }
        } else {
          setShowNetworkProblem(true);
        }
      }
    } else {
      closeAclEdit();
    }
  };

  return (
    <>
      {acl && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head has-text-centered">
              <span className="modal-card-title font-rubik">{t("Access editor")}</span>
            </header>
            <section className="modal-card-body acl-container">
              <div className="is-size-6 has-text-centered mb-3">
                <span>{t("User")}</span>
                <span className="icon is-size-6 is-clickable">
                  <Tippy content={t("Add user")} theme="riskscribe" delay={[1000, 0]}>
                    <i
                      className="fa fa-plus-circle has-text-link"
                      onClick={() => {
                        setShowNewInput(true);
                      }}
                    ></i>
                  </Tippy>
                </span>
              </div>
              <div className="is-size-7 has-text-centered mb-3">
                <span>{t("Editing")}</span>
                <span className="icon is-size-7">
                  <Tippy theme="riskscribe" content={t("Can edit the assessment")}>
                    <i className="fa fa-question-circle"></i>
                  </Tippy>
                </span>
              </div>

              <div className="is-size-7 has-text-centered mb-3">
                <span>{t("Access")}</span>
                <span className="icon is-size-7">
                  <Tippy theme="riskscribe" content={t("Can change the permissions to access the assessment")}>
                    <i className="fa fa-question-circle"></i>
                  </Tippy>
                </span>
              </div>

              <div className="is-size-7 has-text-centered mb-3">
                <span>{t("Actions")}</span>
                <span className="icon is-size-7">
                  <Tippy theme="riskscribe" content={t("Can change the Action Plan")}>
                    <i className="fa fa-question-circle"></i>
                  </Tippy>
                </span>
              </div>
              <div></div>

              {acl.map((access, idx) => (
                <React.Fragment key={access.id}>
                  <div>{access.id}</div>

                  <div className="is-size-7 has-text-centered">
                    <input
                      type="checkbox"
                      checked={(access.permissions & 129) !== 0}
                      onChange={() => handleChange(idx, 1)}
                      disabled={access.permissions === 128 || access.id === user}
                    />
                  </div>
                  <div className="is-size-7 has-text-centered">
                    <input
                      type="checkbox"
                      checked={(access.permissions & 130) !== 0}
                      onChange={() => handleChange(idx, 2)}
                      disabled={access.permissions === 128 || access.id === user}
                    />
                  </div>
                  <div className="is-size-7 has-text-centered">
                    <input
                      type="checkbox"
                      checked={(access.permissions & 132) !== 0}
                      onChange={() => handleChange(idx, 4)}
                      disabled={access.permissions === 128 || access.id === user}
                    />
                  </div>

                  <div>
                    {access.permissions !== 128 && access.id !== user && (
                      <Tippy theme="riskscribe" content={t("Remove access")} delay={[1000, 0]}>
                        <span className="icon is-size-7 is-clickable">
                          <i className="fa fa-trash" onClick={() => handleRemove(idx)}></i>
                        </span>
                      </Tippy>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </section>
            <footer className="modal-card-foot has-background-white is-flex is-justify-content-space-evenly">
              <button className="button is-link" onClick={() => closeAclEdit()}>
                {t("Cancel")}
              </button>
              <button className={`button is-primary ${isLoading ? "is-loading" : ""}`} onClick={handleConfirm}>
                {t("OK")}
              </button>
            </footer>
          </div>
        </div>
      )}

      {showNewInput && <Input title={t("Add user")} onConfirm={handleNew} initialValue="" />}

      <Warning
        show={showInvalidUser}
        onConfirm={() => setShowInvalidUser(false)}
        title={t("Error")}
        content={t("The selected user is not registered on RiskScribe")}
      />

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
    </>
  );
};

export default ACLEditor;
