import React, { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";

import Input from "./Input";
import Warning from "./Warning";

import { t } from "../services/i18n";

const ObjectivesPanel = ({ title, period, objectives, isEditable, selectObjective, addObjective, editObjective, deleteObjective }) => {
  const [highlightedObjective, setHighlightedObjective] = useState(null);
  const [showNewInput, setShowNewInput] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [shouldReload, setShouldReload] = useState(null);

  useEffect(() => {
    const reloadObjective = objectives.find((obj) => obj.description === highlightedObjective?.description || obj.description === shouldReload);

    if (!reloadObjective) {
      setHighlightedObjective(null);
    } else {
      setHighlightedObjective(reloadObjective);
    }

    setShouldReload(null);
  }, [objectives]);

  const handleNewObjective = (objectiveDescription) => {
    if (objectiveDescription != null && !objectives.some((o) => o.description === objectiveDescription)) {
      addObjective(objectiveDescription);
    }
    setShowNewInput(false);
  };

  const handleEditObjective = (newDescription) => {
    if (newDescription !== null && !objectives.some((o) => o.description === newDescription)) {
      setShouldReload(newDescription);
      editObjective(highlightedObjective, newDescription);
    }
    setShowEditInput(false);
  };

  const handleDeleteConfirm = () => {
    if (highlightedObjective) {
      deleteObjective(highlightedObjective);
    }
    setShowDeleteWarning(false);
  };

  return (
    <>
      <div className="objective-title">
        <span className="is-size-5 mr-1">{title}</span>
        <span className="is-size-6">({period})</span>
      </div>
      <div className="header">
        <span className="icon-text">
          <span className="is-size-3 font-rubik mb-3">{t("Objectives")}</span>
          {isEditable && (
            <Tippy content={t("Add new objective")} theme="riskscribe" placement="right" delay={[1000, 0]}>
              <span className="icon is-size-4 has-text-link is-clickable ml-3">
                <i className="fa fa-plus-circle" onClick={() => setShowNewInput(true)}></i>
              </span>
            </Tippy>
          )}
        </span>
      </div>
      <div className="content">
        {objectives.map((obj, idx) => (
          <div
            className={`list-items ${obj === highlightedObjective ? "highlight" : "is-clickable"}`}
            key={idx}
            onClick={() => {
              setHighlightedObjective(obj);
              selectObjective(obj);
            }}
          >
            <span>{obj.description}</span>
            {isEditable && (
              <Tippy content={t("Edit objective")} theme="riskscribe" delay={[1000, 0]}>
                <span className={`icon ${obj === highlightedObjective ? "is-clickable" : "is-hidden"}`}>
                  <i className="fa fa-edit" onClick={() => setShowEditInput(true)}></i>
                </span>
              </Tippy>
            )}
            {isEditable && (
              <Tippy content={t("Delete objective")} theme="riskscribe" delay={[1000, 0]}>
                <span className={`icon ${obj === highlightedObjective ? "is-clickable" : "is-hidden"}`}>
                  <i className="fa fa-trash" onClick={() => setShowDeleteWarning(true)}></i>
                </span>
              </Tippy>
            )}
            <Tippy theme="riskscribe" content={t("Objective has risks that have not been evaluated")} delay={[1000, 0]}>
              <span
                className={`icon ${
                  obj.risks.some(
                    (risk) => risk.inherent_impact === 1 && risk.inherent_probability === 1 && risk.residual_probability === 1 && risk.residual_impact === 1
                  )
                    ? "has-text-primary"
                    : "is-hidden"
                }`}
              >
                <i className="fa fa-exclamation-triangle"></i>
              </span>
            </Tippy>
          </div>
        ))}
      </div>
      {showNewInput && <Input title={t("Add new objective")} onConfirm={handleNewObjective} initialValue="" />}

      {showEditInput && <Input title={t("Edit objective")} onConfirm={handleEditObjective} initialValue={highlightedObjective.description} />}

      <Warning
        show={showDeleteWarning}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setShowDeleteWarning(false)}
        title={t("Confirmation")}
        content={t("The selected objective will be deleted")}
      />
    </>
  );
};

export default ObjectivesPanel;
