import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { es, enUS } from "date-fns/locale";
import ReactQuill from "react-quill";
import Tippy from "@tippyjs/react";

import Input from "./Input";
import Warning from "./Warning";

import { getAssessment, addAction, updateAction, deleteAction } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

import debounce from "../utilities/debounce";

const PlanEditor = ({ assessmentId, language }) => {
  const navigate = useNavigate();

  const [plan, setPlan] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [shouldSelectLast, setShouldSelectLast] = useState(false);
  const [showNewInput, setShowNewInput] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);

  const changeAction = (changedAction) => {
    updateAction(assessmentId, changedAction).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const debouncedChangeAction = useRef(debounce(changeAction, 1000)).current;

  useEffect(() => {
    if (shouldSelectLast) {
      setCurrentAction(plan.length - 1);
      setShouldSelectLast(false);
    }
  }, [shouldSelectLast, plan]);

  useEffect(() => {
    registerLocale("es", es);
    registerLocale("en", enUS);
  });

  useEffect(() => {
    if (assessmentId) {
      const loadPlan = async () => {
        try {
          let assessmentData = await getAssessment(assessmentId);
          setPlan(assessmentData.plan);
        } catch (error) {
          if (error.name === "APIError") {
            if (error.status === 401) {
              setShowSessionExpired(true);
            } else if (error.status === 403) {
              setShowAuthorizationError(true);
            } else {
              setShowServerError(true);
            }
          } else {
            setShowNetworkProblem(true);
          }
        }
      };

      loadPlan();
    } else {
      navigate("/");
    }
  }, [assessmentId]);

  const handleNewAction = async (name) => {
    setShowNewInput(false);

    if (name != null && plan.every((action) => action.name !== name)) {
      try {
        let newAction = {
          name: name,
          start: +new Date(),
          end: +new Date(),
          lead: "",
          description: "",
        };

        await addAction(assessmentId, newAction);

        setPlan((prev) => [...prev, newAction]);
        setShouldSelectLast(true);
      } catch (error) {
        if (error.name === "APIError") {
          if (error.status === 401) {
            setShowSessionExpired(true);
          } else if (error.status === 403) {
            setShowAuthorizationError(true);
          } else {
            setShowServerError(true);
          }
        } else {
          setShowNetworkProblem(true);
        }
      }
    }
  };

  const handleDeleteConfirm = async () => {
    setShowDeleteWarning(false);

    try {
      await deleteAction(assessmentId, plan[currentAction]);

      setPlan((prev) => prev.filter((_, idx) => idx !== currentAction));
      setCurrentAction(null);
    } catch (error) {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    }
  };

  const handleEditorChange = (content, _, source) => {
    if (source === "user") {
      // FIX(?) for ReactQuill bullshit - probably needs more testing
      debouncedChangeAction({
        name: plan[currentAction].name,
        start: plan[currentAction].start,
        end: plan[currentAction].end,
        lead: plan[currentAction].lead,
        description: content,
      });

      setPlan((prev) =>
        prev.map((action, idx) =>
          idx !== currentAction
            ? action
            : {
                ...action,
                description: content,
              }
        )
      );
    }
  };

  const handleStartChange = (date) => {
    debouncedChangeAction({
      name: plan[currentAction].name,
      start: date.valueOf(),
      end: plan[currentAction].end,
      lead: plan[currentAction].lead,
      description: plan[currentAction].description,
    });

    setPlan((prev) =>
      prev.map((action, idx) =>
        idx !== currentAction
          ? action
          : {
              ...action,
              start: date.valueOf(),
            }
      )
    );
  };

  const handleLeadChange = (event) => {
    let lead = event.target.value;

    debouncedChangeAction({
      name: plan[currentAction].name,
      start: plan[currentAction].start,
      end: plan[currentAction].end,
      lead: lead,
      description: plan[currentAction].description,
    });

    setPlan((prev) =>
      prev.map((action, idx) =>
        idx !== currentAction
          ? action
          : {
              ...action,
              lead: lead,
            }
      )
    );
  };

  const handleEndChange = (date) => {
    debouncedChangeAction({
      name: plan[currentAction].name,
      start: plan[currentAction].start,
      end: date.valueOf(),
      lead: plan[currentAction].lead,
      description: plan[currentAction].description,
    });

    setPlan((prev) =>
      prev.map((action, idx) =>
        idx !== currentAction
          ? action
          : {
              ...action,
              end: date.valueOf(),
            }
      )
    );
  };

  return (
    <>
      <div className="plan-editor-container">
        <div className="action-panel">
          <div className="header">
            <span className="icon-text">
              <span className="is-size-3 font-rubik mb-3">{t("Actions")}</span>
              <Tippy content={t("Add new action")} theme="riskscribe" placement="right" delay={[1000, 0]}>
                <span className="icon is-size-4 has-text-link is-clickable ml-3">
                  <i className="fa fa-plus-circle" onClick={() => setShowNewInput(true)}></i>
                </span>
              </Tippy>
            </span>
          </div>
          <div className="content">
            {plan &&
              plan.map((action, idx) => (
                <div className={`list-items ${idx === currentAction ? "highlight" : "is-clickable"}`} key={idx} onClick={() => setCurrentAction(idx)}>
                  <span>{action.name}</span>
                  <Tippy content={t("Delete action")} theme="riskscribe" delay={[1000, 0]}>
                    <span className={`icon ${idx === currentAction ? "is-clickable" : "is-hidden"}`}>
                      <i className="fa fa-trash" onClick={() => setShowDeleteWarning(true)}></i>
                    </span>
                  </Tippy>
                </div>
              ))}
          </div>
        </div>

        {currentAction !== null && (
          <div className="plan-details">
            <div className="plan-basics">
              <span className="has-text-weight-bold mr-2">{t("Lead")}: </span>
              <input className="input" value={plan[currentAction].lead} onChange={handleLeadChange} />
              <span className="has-text-weight-bold mr-2 ml-2">{t("Start date")}: </span>
              <DatePicker
                locale={language}
                dateFormat={language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                selected={new Date(plan[currentAction].start)}
                onChange={handleStartChange}
                className="input"
              />
              <span className="has-text-weight-bold mr-2 ml-2">{t("End date")}: </span>
              <DatePicker
                locale={language}
                dateFormat={language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                selected={new Date(plan[currentAction].end)}
                onChange={handleEndChange}
                className="input"
              />
            </div>

            <div className="quill-container" onDrop={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
              <ReactQuill
                bounds=".quill-container"
                theme="snow"
                formats={["header", "bold", "italic", "underline", "list", "bullet", "link"]}
                value={plan[currentAction].description}
                onChange={handleEditorChange}
              />
            </div>
          </div>
        )}
      </div>

      {showNewInput && <Input title={t("Add new action")} onConfirm={handleNewAction} initialValue="" />}

      <Warning
        show={showDeleteWarning}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setShowDeleteWarning(false)}
        title={t("Confirmation")}
        content={t("The selected action will be deleted")}
      />

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
    </>
  );
};

export default PlanEditor;
