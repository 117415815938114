import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Tippy from "@tippyjs/react";

import Warning from "./Warning";

import { createAssessment } from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

import { internalCriteria, availableTemplates } from "../utilities/defaults";

const NewAssessment = ({ assessments, updateAssessments, customCriteria, onClose, tier, language }) => {
  const defaultCriteria = internalCriteria(language);
  const templateList = availableTemplates(language);

  const [title, setTitle] = useState("");
  const [period, setPeriod] = useState("");
  const [copyId, setCopyId] = useState(null);
  const [template, setTemplate] = useState(null);
  const [criteria, setCriteria] = useState(defaultCriteria[0].name);
  const [isCopy, setIsCopy] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);
  const [showInvalidParameters, setShowInvalidParameters] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!showInvalidParameters) {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          onConfirm();
        }

        if (event.key === "Escape") {
          event.preventDefault();
          event.stopPropagation();
          onClose();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [title, period, copyId, template, criteria, showInvalidParameters]);

  const onConfirm = async () => {
    if (title.trim() === "" || period.trim() === "") {
      setShowInvalidParameters(true);
    } else {
      setIsLoading(true);

      try {
        let newAssessmentId = await createAssessment(title, period, copyId, template, criteria);

        let newAssessment = {
          id: newAssessmentId,
          title: title,
          period: period,
          permissions: 128,
        };

        updateAssessments((prev) => [...prev, newAssessment]);
        setIsLoading(false);
        onClose();
      } catch (error) {
        setIsLoading(false);
        if (error.name === "APIError") {
          if (error.status === 401) {
            setShowSessionExpired(true);
          } else if (error.status === 403) {
            setShowAuthorizationError(true);
          } else {
            setShowServerError(true);
          }
        } else {
          setShowNetworkProblem(true);
        }
      }
    }
  };

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head has-text-centered">
            <span className="modal-card-title font-rubik">{t("New risk assessment")}</span>
          </header>
          <form>
            <section className="modal-card-body">
              <div className="field">
                <label className="label" htmlFor="title">
                  {t("Title")}:
                </label>
                <div className="control">
                  <input id="title" type="text" className="input is-primary" value={title} onChange={(e) => setTitle(e.target.value)} autoFocus />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="period">
                  {t("Period")}:
                </label>
                <div className="control">
                  <input
                    id="period"
                    type="text"
                    className="input is-primary"
                    value={period}
                    onChange={(e) => {
                      setPeriod(e.target.value);
                    }}
                  />
                </div>
              </div>

              {!isTemplate && assessments?.length > 0 && (
                <div className="field">
                  <label className="label" htmlFor="copy">
                    {t("Assessment to copy")}:
                  </label>
                  <div className="control">
                    <div className="select is-primary">
                      <select
                        id="copy"
                        onChange={(e) => {
                          setCopyId(e.target.value);
                          setIsCopy(e.target.value === "" ? false : true);
                        }}
                      >
                        <option value="">{t("None")}</option>
                        {assessments.map((assessmentData, idx) => (
                          <option value={assessmentData.id} key={idx}>{`${assessmentData.title} (${assessmentData.period})`}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {!isCopy && (
                <div className="field is-disabled">
                  <label className="label" htmlFor="template">
                    {t("Template to use")}:
                  </label>
                  <div className="control">
                    <div className="select is-primary">
                      <Tippy
                        theme="riskscribe"
                        content={t("Templates can only be used in the Professional and Corporate plans")}
                        placement="right"
                        delay={[0, 0]}
                        disabled={tier >= 2}
                      >
                        <select
                          id="template"
                          onChange={(e) => {
                            setTemplate(e.target.value);
                            setIsTemplate(e.target.value === "" ? false : true);
                          }}
                        >
                          <option value="">{t("None")}</option>
                          {templateList.map((templateName, idx) => (
                            <option key={idx} disabled={tier < 2}>
                              {templateName}
                            </option>
                          ))}
                        </select>
                      </Tippy>
                    </div>
                  </div>
                </div>
              )}

              {!isCopy && (
                <div className="field">
                  <label className="label" htmlFor="criteria">
                    {t("Risk assessment criteria")}:
                  </label>
                  <div className="control">
                    <div className="select is-primary">
                      <select id="criteria" onChange={(e) => setCriteria(e.target.value)}>
                        {[...defaultCriteria, ...customCriteria].map((c) => (
                          <option key={c.name}>{c.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </section>
            <footer className="modal-card-foot has-background-white is-flex is-justify-content-space-evenly">
              <div className="control">
                <button type="button" className="button is-link" onClick={onClose}>
                  {t("Cancel")}
                </button>
              </div>
              <div className="control">
                <button type="button" className={`button is-primary ${isLoading ? "is-loading" : ""}`} onClick={onConfirm}>
                  {t("OK")}
                </button>
              </div>
            </footer>
          </form>
        </div>
      </div>

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
      <Warning
        show={showInvalidParameters}
        onConfirm={() => {
          setShowInvalidParameters(false);
        }}
        title={t("Error")}
        content={t("Creating a new assessment requires a valid name and period")}
      />
    </>
  );
};

export default NewAssessment;
