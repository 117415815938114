import React, { useState, useEffect } from "react";

import CustomDropdown from "./CustomDropdown";

import { t } from "../services/i18n";

const RiskDetailsPanel = ({ objective, risk, isEditable, criteria, editRisk }) => {
  const [exposure, setExposure] = useState({});

  useEffect(() => {
    if (risk) {
      setExposure(calculateExposures(risk.inherent_probability, risk.inherent_impact, risk.residual_probability, risk.residual_impact));
    }
  }, [risk]);

  function updateInherentProbability(value) {
    if (risk.inherent_probability !== value)
      editRisk(objective, risk, risk.description, value, risk.inherent_impact, risk.residual_probability, risk.residual_impact);
  }

  function updateInherentImpact(value) {
    if (risk.inherent_impact !== value)
      editRisk(objective, risk, risk.description, risk.inherent_probability, value, risk.residual_probability, risk.residual_impact);
  }

  function updateResidualProbability(value) {
    if (risk.residual_probability !== value)
      editRisk(objective, risk, risk.description, risk.inherent_probability, risk.inherent_impact, value, risk.residual_impact);
  }

  function updateResidualImpact(value) {
    if (risk.residual_impact !== value)
      editRisk(objective, risk, risk.description, risk.inherent_probability, risk.inherent_impact, risk.residual_probability, value);
  }

  function calculateExposures(inherentProbability, inherentImpact, residualProbability, residualImpact) {
    let inherentExposure = (inherentProbability * criteria.exposure.probability) / 100 + (inherentImpact * criteria.exposure.impact) / 100;
    inherentExposure = Math.round(inherentExposure * 10) / 10;
    let inherentHeat =
      inherentExposure < criteria.tolerance.caution ? "is-success" : inherentExposure >= criteria.tolerance.unacceptable ? "is-danger" : "is-warning";

    let residualExposure = (residualProbability * criteria.exposure.probability) / 100 + (residualImpact * criteria.exposure.impact) / 100;
    residualExposure = Math.round(residualExposure * 10) / 10;
    let residualHeat =
      residualExposure < criteria.tolerance.caution ? "is-success" : residualExposure >= criteria.tolerance.unacceptable ? "is-danger" : "is-warning";

    return {
      inherentExposure: inherentExposure,
      inherentHeat: inherentHeat,
      residualExposure: residualExposure,
      residualHeat: residualHeat,
    };
  }

  return (
    <div className="risk-details-panel">
      <div className="header is-size-3 font-rubik mb-3">{t("Risk analysis")}</div>
      {risk && (
        <>
          <div className="risk-inherent">
            <div className="exposure-panel">
              <div className="exposure-header">{t("Inherent exposure")}</div>
              <div className="probability-label">{t("Probability")}</div>
              <div className="probability-selector">
                <CustomDropdown
                  isEditable={isEditable}
                  names={criteria.probability.names}
                  descriptions={criteria.probability.descriptions}
                  values={Array.from({ length: criteria.probability.total }, (_, i) => i + 1)}
                  initialValue={risk.inherent_probability}
                  onChange={(value) => updateInherentProbability(value)}
                />
              </div>
              <div className="impact-label">{t("Impact")}</div>
              <div className="impact-selector">
                <CustomDropdown
                  isEditable={isEditable}
                  names={criteria.impact.names}
                  descriptions={criteria.impact.descriptions}
                  values={Array.from({ length: criteria.impact.total }, (_, i) => i + 1)}
                  initialValue={risk.inherent_impact}
                  onChange={(value) => updateInherentImpact(value)}
                />
              </div>
              <div className="exposure">
                <span className={`tag is-medium ${exposure.inherentHeat}`}>
                  {exposure.inherentHeat === "is-success"
                    ? `${t("Acceptable")} (${exposure.inherentExposure})`
                    : exposure.inherentHeat === "is-danger"
                    ? `${t("Unacceptable")} (${exposure.inherentExposure})`
                    : `${t("Caution")} (${exposure.inherentExposure})`}
                </span>
              </div>
            </div>
          </div>
          <div className="risk-residual">
            <div className="exposure-panel">
              <div className="exposure-header">{t("Residual exposure")}</div>
              <div className="probability-label">{t("Probability")}</div>
              <div className="probability-selector">
                <CustomDropdown
                  isEditable={isEditable}
                  names={criteria.probability.names}
                  descriptions={criteria.probability.descriptions}
                  values={Array.from({ length: criteria.probability.total }, (_, i) => i + 1)}
                  initialValue={risk.residual_probability}
                  onChange={(value) => updateResidualProbability(value)}
                />
              </div>
              <div className="impact-label">{t("Impact")}</div>
              <div className="impact-selector">
                <CustomDropdown
                  isEditable={isEditable}
                  names={criteria.impact.names}
                  descriptions={criteria.impact.descriptions}
                  values={Array.from({ length: criteria.impact.total }, (_, i) => i + 1)}
                  initialValue={risk.residual_impact}
                  onChange={(value) => updateResidualImpact(value)}
                />
              </div>
              <div className="exposure">
                <span className={`tag is-medium ${exposure.residualHeat}`}>
                  {exposure.residualHeat === "is-success"
                    ? `${t("Acceptable")} (${exposure.residualExposure})`
                    : exposure.residualHeat === "is-danger"
                    ? `${t("Unacceptable")} (${exposure.residualExposure})`
                    : `${t("Caution")} (${exposure.residualExposure})`}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RiskDetailsPanel;
