import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ObjectivesPanel from "./ObjectivesPanel";
import RisksPanel from "./RisksPanel";
import RiskDetailsPanel from "./RiskDetailsPanel";
import ControlsPanel from "./ControlsPanel";
import Warning from "./Warning";

import {
  getAssessment,
  addObjective,
  updateObjective,
  deleteObjective,
  addRisk,
  addRisksAI,
  updateRisk,
  deleteRisk,
  addControl,
  updateControl,
  deleteControl,
} from "../services/RiskScribeSDK";
import { t } from "../services/i18n";

const Assessment = ({ assessmentId, isEditable, language }) => {
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [riskHighlightSignal, setRiskHighlightSignal] = useState(false);
  const [controlHighlightSignal, setControlHighlightSignal] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAIQuota, setShowAIQuota] = useState(false);
  const [showAIError, setShowAIError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);
  const [shouldUpdateObjective, setShouldUpdateObjective] = useState(null);
  const [shouldUpdateRisk, setShouldUpdateRisk] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (assessmentId) {
      const loadAssessment = async () => {
        try {
          let assessmentData = await getAssessment(assessmentId);
          setAssessment(assessmentData);
        } catch (error) {
          if (error.name === "APIError") {
            if (error.status === 401) {
              setShowSessionExpired(true);
            } else if (error.status === 403) {
              setShowAuthorizationError(true);
            } else {
              setShowServerError(true);
            }
          } else {
            setShowNetworkProblem(true);
          }
        }
      };

      loadAssessment();
    } else {
      navigate("/");
    }
  }, [assessmentId]);

  useEffect(() => {
    if (assessment) {
      const reloadObjective = assessment.objectives.find(
        (obj) => obj.description === selectedObjective?.description || obj.description === shouldUpdateObjective
      );

      if (!reloadObjective) {
        setSelectedObjective(null);
        setSelectedRisk(null);
      } else if (reloadObjective) {
        setSelectedObjective(reloadObjective);

        const reloadRisk = reloadObjective.risks.find((risk) => risk.description === selectedRisk?.description || risk.description === shouldUpdateRisk);

        if (!reloadRisk) {
          setSelectedRisk(null);
        } else {
          setSelectedRisk(reloadRisk);
        }
      }

      setShouldUpdateObjective(null);
      setShouldUpdateRisk(null);
    }
  }, [assessment]);

  const selectObjective = (objective) => {
    setSelectedObjective(objective);
    setSelectedRisk(null);
    setControlHighlightSignal((prev) => !prev);
    setRiskHighlightSignal((prev) => !prev);
  };

  const selectRisk = (risk) => {
    setSelectedRisk(risk);
    setControlHighlightSignal((prev) => !prev);
  };

  const handleAddObjective = (objectiveDescription) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: [
        ...prev.objectives,
        {
          description: objectiveDescription,
          risks: [],
        },
      ],
    }));

    addObjective(assessment._id, objectiveDescription).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleEditObjective = (objective, newDescription) => {
    setShouldUpdateObjective(newDescription);

    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) => (o.description === objective.description ? { ...o, description: newDescription } : o)),
    }));

    updateObjective(assessment._id, objective.description, newDescription).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleDeleteObjective = (objective) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.filter((o) => o.description !== objective.description),
    }));

    deleteObjective(assessment._id, objective.description).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleAddRisk = (objective, riskDescription) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) =>
        o.description === objective.description
          ? {
              ...o,
              risks: [
                ...o.risks,
                {
                  description: riskDescription,
                  inherent_probability: 1,
                  inherent_impact: 1,
                  residual_probability: 1,
                  residual_impact: 1,
                  controls: [],
                },
              ],
            }
          : o
      ),
    }));

    addRisk(assessment._id, objective.description, riskDescription).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleAddRisksAI = async (objective) => {
    try {
      let newRiskDescriptions = await addRisksAI(assessment._id, objective.description);

      let newRisks = newRiskDescriptions.map((riskDescription) => {
        return {
          description: riskDescription,
          inherent_probability: 1,
          inherent_impact: 1,
          residual_probability: 1,
          residual_impact: 1,
          controls: [],
        };
      });

      setAssessment((prev) => ({
        ...prev,
        objectives: prev.objectives.map((o) =>
          o.description === objective.description
            ? {
                ...o,
                risks: newRisks,
              }
            : o
        ),
      }));
    } catch (error) {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          if (error.message === "Tier 0 user already used up all AI assisstant uses") {
            setShowAIQuota(true);
          } else {
            setShowAuthorizationError(true);
          }
        } else if (error.status === 503) {
          setShowAIError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    }
  };

  const handleEditRisk = (objective, risk, newDescription, newInherentProbability, newInherentImpact, newResidualProbability, newResidualImpact) => {
    setShouldUpdateRisk(newDescription);

    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) =>
        o.description === objective.description
          ? {
              ...o,
              risks: o.risks.map((r) =>
                r.description === risk.description
                  ? {
                      ...r,
                      description: newDescription,
                      inherent_probability: newInherentProbability,
                      inherent_impact: newInherentImpact,
                      residual_probability: newResidualProbability,
                      residual_impact: newResidualImpact,
                    }
                  : r
              ),
            }
          : o
      ),
    }));

    updateRisk(
      assessment._id,
      objective.description,
      risk.description,
      newDescription,
      newInherentProbability,
      newInherentImpact,
      newResidualProbability,
      newResidualImpact
    ).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleDeleteRisk = (objective, risk) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) =>
        o.description === objective.description
          ? {
              ...o,
              risks: o.risks.filter((r) => r.description !== risk.description),
            }
          : o
      ),
    }));

    deleteRisk(assessment._id, objective.description, risk.description).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleAddControl = (objective, risk, control) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) =>
        o.description === objective.description
          ? {
              ...o,
              risks: o.risks.map((r) =>
                r.description === risk.description
                  ? {
                      ...r,
                      controls: [...r.controls, { description: control }],
                    }
                  : r
              ),
            }
          : o
      ),
    }));

    addControl(assessment._id, objective.description, risk.description, control).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleEditControl = (objective, risk, control, newDescription) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) =>
        o.description === objective.description
          ? {
              ...o,
              risks: o.risks.map((r) =>
                r.description === risk.description
                  ? {
                      ...r,
                      controls: r.controls.map((c) => (c.description === control.description ? { ...c, description: newDescription } : c)),
                    }
                  : r
              ),
            }
          : o
      ),
    }));

    updateControl(assessment._id, objective.description, risk.description, control.description, newDescription).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  const handleDeleteControl = (objective, risk, control) => {
    setAssessment((prev) => ({
      ...prev,
      objectives: prev.objectives.map((o) =>
        o.description === objective.description
          ? {
              ...o,
              risks: o.risks.map((r) =>
                r.description === risk.description
                  ? {
                      ...r,
                      controls: r.controls.filter((c) => c.description !== control.description),
                    }
                  : r
              ),
            }
          : o
      ),
    }));

    deleteControl(assessment._id, objective.description, risk.description, control.description).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });
  };

  return (
    <>
      {assessment && (
        <div className="assessment-container">
          <div className="objectives-panel">
            <ObjectivesPanel
              title={assessment.title}
              period={assessment.period}
              objectives={assessment.objectives}
              isEditable={isEditable}
              selectObjective={selectObjective}
              addObjective={handleAddObjective}
              editObjective={handleEditObjective}
              deleteObjective={handleDeleteObjective}
              language={language}
            />
          </div>
          <div className="risks-container">
            <RisksPanel
              objective={selectedObjective}
              isEditable={isEditable}
              selectRisk={selectRisk}
              highlightSignal={riskHighlightSignal}
              addRisk={handleAddRisk}
              addRisksAI={handleAddRisksAI}
              editRisk={handleEditRisk}
              deleteRisk={handleDeleteRisk}
              language={language}
            />
            <RiskDetailsPanel
              objective={selectedObjective}
              risk={selectedRisk}
              isEditable={isEditable}
              editRisk={handleEditRisk}
              criteria={assessment.criteria}
              language={language}
            />
            <ControlsPanel
              objective={selectedObjective}
              risk={selectedRisk}
              isEditable={isEditable}
              highlightSignal={controlHighlightSignal}
              addControl={handleAddControl}
              editControl={handleEditControl}
              deleteControl={handleDeleteControl}
              language={language}
            />
          </div>
        </div>
      )}

      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
      <Warning
        show={showAIQuota}
        onConfirm={() => {
          setShowAIQuota(false);
        }}
        title={t("Error")}
        content={t("Users on the free plan have a limited amount of uses of Artificial Intelligence functions")}
      />
      <Warning
        show={showAIError}
        onConfirm={() => {
          setShowAuthorizationError(false);
        }}
        title={t("Error")}
        content={t("There was an error accessing the services provided by OpenAI. Please try again in a few minutes.")}
      />
    </>
  );
};

export default Assessment;
