var i18nLanguage = "en";

const spanish = {
  Confirmation: "Confirmación",
  Notice: "Aviso",
  Cancel: "Cancelar",
  OK: "Aceptar",
  Error: "Error",
  Warning: "Advertencia",
  "Unable to process your request, please try again": "No fue posible procesar su solicitud, por favor intente nuevamente",
  "The selected user is not registered on RiskScribe": "El usuario seleccionado no está registrado en RiskScribe.",
  "Unable to connect to the server. Please check your Internet connection and try again.":
    "No fue posible conectarse con el servidor. Por favor verifique su conexión a Internet y vuelva a intentar.",
  "Your session has expired, please login again.": "Su sesión ha expirado, por favor ingrese nuevamente.",
  "The selected assessment will be deleted": "La evaluación seleccionada será eliminada",
  "The selected control will be deleted": "El control seleccionado será eliminado",
  "The selected criteria will be deleted": "El criterio seleccionado será eliminado",
  "The selected risk will be deleted": "El riesgo seleccionado será eliminado",
  "The selected objective will be deleted": "El objetivo seleccionado será eliminado",
  "The selected action will be deleted": "La acción seleccionada será eliminada",
  "Invalid user or password": "Usuario o contraseña incorrecto",
  "User successfully registered, please follow the instructions in the email you will receive":
    "Solicitud realizada correctamente, siga las instrucciones en el correo que recibirá",
  "User successfully reset, please follow the instructions in the email you will receive":
    "Solicitud realizada correctamente, siga las instrucciones en el correo que recibirá",
  "Are you sure you wish to log out?": "¿Está seguro que desea salir?",
  "A criteria cannot be created without a name or with a duplicate one": "No es posible crear un criterio sin nombre o con un nombre repetido.",
  "Generating a list of risks using Artificial Intelligence is a valuable help but the results should be carefully analyzed to ensure they are adequate. Please be aware that usually you will need to add, modify or remove some risks until the final list is correct.":
    "La generación de riesgos utilizando Inteligencia Artificial es una ayuda valiosa pero los resultados deben ser evaluados con cuidado y criterio para garantizar que se adaptan correctamente a la realidad. Tenga en cuenta que en general será necesario agregar o eliminar riesgos hasta completar una lista apropiada.",
  "Passwords do not match": "Las contraseñas ingresadas no coinciden",
  "Password successfully changed": "Contraseña modificada correctamente",
  "Please wait - the process can take a few seconds": "Por favor espere - el proceso puede tardar unos segundos",
  "Access editor": "Editor de Accesos",
  User: "Usuario",
  "Add user": "Agregar usuario",
  Editing: "Edición",
  "Can edit the assessment": "Puede editar la evaluación",
  Access: "Accesos",
  "Can change the permissions to access the assessment": "Puede cambiar los permisos de acceso a la evaluación",
  Actions: "Acciones",
  "Can change the Action Plan": "Puede editar el Plan de Acción",
  "Remove access": "Eliminar acceso",
  "Password change": "Cambio de contraseña",
  Password: "Contraseña",
  "Password confirmation": "Confirmación de contraseña",
  "Language setup": "Configuración de idioma",
  Language: "Idioma",
  "Risk assessments": "Evaluaciones de riesgo",
  "Evaluation criteria": "Criterios de evaluación",
  Criteria: "Criterios",
  Configuration: "Configuración",
  "Log in": "Ingresar",
  "Log out": "Salir",
  Registration: "Registración",
  "Access management": "Gestión de accesos",
  "Action plan": "Plan de acción",
  Report: "Reporte",
  Delete: "Eliminar",
  "Start your first assessment": "Cree su primera evaluación",
  "New assessment": "Nueva evaluación",
  Controls: "Controles",
  "Add new control": "Agregar nuevo control",
  "Edit control": "Editar control",
  "Delete control": "Borrar control",
  "Add assessment criteria": "Crear nuevo criterio de evaluación",
  "Delete assessment criteria": "Borrar criterio de evaluación",
  Probability: "Probabilidad",
  Impact: "Impacto",
  Exposure: "Exposición",
  "Caution limit": "Límite para atención",
  "Unacceptable limit": "Límite inaceptable",
  Email: "Correo electrónico",
  "Request password reset": "Solicitar reinicilización de contraseña",
  "Remember me?": "¿Recordarme?",
  "Forgot your password?": "¿Olvidó su contraseña?",
  "New risk assessment": "Nueva evaluación de riesgos",
  Title: "Título",
  Period: "Período",
  "Assessment to copy": "Evaluación a copiar",
  None: "Ninguna",
  "None ": "Ninguno",
  "Template to use": "Plantilla a utilizar",
  "Risk assessment criteria": "Criterio para la evaluación de riesgos",
  "New risk assessment criteria": "Nuevo criterio de evaluación de riesgos",
  Name: "Nombre",
  "Criteria to copy as basis": "Criterio a copiar como base",
  "Number of categories": "Número de categorías",
  Objectives: "Objetivos",
  "Add new objective": "Crear nuevo objetivo",
  "Edit objective": "Editar objetivo",
  "Delete objective": "Borrar objetivo",
  "Add new action": "Crear nueva acción",
  "Delete action": "Borrar acción",
  Lead: "Responsable",
  "Start date": "Fecha de inicio",
  "End date": "Fecha de finalización",
  "Email is invalid": "Correo electrónico inválido",
  Register: "Registrarse",
  "Report components": "Componentes del reporte",
  "Risk summary": "Resumen de riesgos",
  "Complete list of all risks in the assessment": "Listado general de los riesgos de la evaluación",
  "All actions included in the Action plan": "Todas las acciones definidas en el Plan de acción",
  "Annex - Main risks": "Anexo - Principales riesgos",
  "List of the 10 risks with highest residual exposure": "Listado de los 10 riesgos con mayor exposición residual",
  "Annex - Critical controls": "Anexo - Controles críticos",
  "Controls that mitigate unacceptable inherent exposures and whose effectiveness must be monitored":
    "Controles que mitigan exposiciones inherentes inaceptables y cuya efectividad debe ser monitoreada",
  "Annex - Risk assessment criteria": "Anexo - Criterios de evaluación de riesgos",
  "Probability and impact tables, exposure formula and tolerance limits used in the assessment":
    "Tablas de probabilidad e impacto, exposición y tolerancia utilizadas en la evaluación",
  "Password successfuly updated, please log in again": "Contraseña modificada correctamente, por favor ingrese nuevamente",
  "Risk analysis": "Análisis del riesgo",
  "Inherent exposure": "Exposición Inherente",
  "Residual exposure": "Exposición Residual",
  Acceptable: "Aceptable",
  Unacceptable: "Inaceptable",
  Caution: "Atención",
  Risks: "Riesgos",
  "Add new risk": "Crear nuevo riesgo",
  "Add risks automatically": "Generar riesgos automáticamente",
  "Edit risk": "Editar riesgo",
  "Delete risk": "Borrar riesgo",
  "Risk has not been evaluated": "El riesgo no ha sido evaluado",
  "Objective has risks that have not been evaluated": "El objetivo tiene riesgos que no han sido evaluados",
  "Assessment has risks that have not been evaluated": "La evaluación tiene riesgos que no han sido evaluados",
  "Available in all paid plans": "Disponible en todos los planes pagos",
  "Available in the Corporate plan": "Disponible en el plan Corporativo",
  Plan: "Plan",
  "Current plan: ": "Plan actual: ",
  "Your license is valid until: ": "Su licencia es válida hasta: ",
  Corporate: "Corporativo",
  Professional: "Profesional",
  Personal: "Personal",
  Free: "Gratuito",
  "Upgrade to the Personal plan": "Pasar al plan Personal",
  "Extend your Personal plan": "Extender su plan Personal",
  "Upgrade to the Professional plan": "Pasar al plan Profesional",
  "Extend your Professional plan": "Extender su plan Profesional",
  "Upgrade to the Corporate plan": "Pasar al plan Corporativo",
  "Extend your Corporate plan": "Extender su plan Corporativo",
  "You will now be redirected to Stripe (one of the leading and most secure global payment services) to pay for your license. The RiskScribe platform does not access or store your payment information.":
    "A continuación será redirigido a Stripe (uno de los servicios de pago en línea líderes y más seguros a nivel mundial) para pagar por su licencia. La plataforma RiskScribe no accede o guarda su información de pago.",
  "You have a perpetual license": "Usted tiene una licencia perpetua",
  "Your license purchase was processed correctly. Please login again to start using it.":
    "La compra de su licencia fue procesada correctamente. Por favor ingrese nuevamente para empezar a utilizarla.",
  "Your purchase of a new license has been cancelled.": "Su compra de una nueva licencia fue cancelada.",
  "There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.":
    "Ocurrió un problema en el servidor al procesar su pedido. Por favor intente nuevamente en unos minutos y contacte a soporte si el problema sigue ocurriendo.",
  "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support.":
    "Su intento de realizar una operación sin la autorización correcta ha sido registrado y será investigado. Si cree que esto es un error, por favor contacte a soporte.",
  "Users on the free plan have a limited amount of uses of Artificial Intelligence functions":
    "Los usuarios en el plan gratuito tienen un número de limitado de usos de las funciones de Inteligencia Artificial",
  "There was an error accessing the services provided by OpenAI. Please try again in a few minutes.":
    "Hubo un error accediendo a los servicios provistos por OpenAI. Por favor intente de nuevo en unos minutos.",
  "Templates can only be used in the Professional and Corporate plans":
    "Las planillas de riesgos solo están disponibles en los planes Profesional y Corporativo",
  "Creating a new assessment requires a valid name and period": "Se requiere un nombre y período válido para crear una nueva evaluación",
  "You are registering for a completely free version of RiskScribe. Some functions are limited to the paid Plans which can be bought from your user menu.":
    "Se está registrando para una versión completamente gratuita de RiskScribe. Algunas funciones están limitadas a los Planes pagos que pueden adquirirse desde su menu de usuario.",
  "User guide": "Guía de uso",
  "Compare plans": "Comparar planes",
  Support: "Soporte",
  Message: "Mensaje",
  "We are here to help you with any issues you are having with the RiskScribe platform or listen to your improvement suggestions.":
    "Estamos siempre disponibles para ayudarlo con cualquier problema que tenga con la plataforma RiskScribe o para escuchar sus sugerencias de mejora.",
  Send: "Enviar",
  "Your message has been sent, we'll be in touch shortly to your registered email.":
    "Su mensaje ha sido enviado, nos pondremos en contacto a la brevedad al correo electrónico con el que está registrado.",
  "Unable to send your message, please try again later": "No fue posible enviar su mensaje, por favor intenter nuevamente en unos minutos.",
  Contact: "Contacto",
  Login: "Iniciar sesión",
  "Request password reset": "Solicitar nueva contraseña",
  "Enter new password": "Ingrese su nueva contraseña",
  "Unavailable in the free plan": "No disponible en el plan gratuito",
  "Unlock an unlimited number of assessments and AI assistant uses with any paid plan.":
    "Acceda a un número ilimitado de evaluaciones y usos del asistente de Inteligencia Artifical con cualquier plan pago.",
  "Depending on the plan you choose, you will also have access to premium features such as:":
    "Dependiendo del plan que elija, tendrá acceso también a funciones premium como:",
  "Action plans and configurable reports": "Planes de acción y reportes configurables",
  "Comprehensive templates for ISO 9001, 14001, 27001, and 45001": "Plantillas completas para ISO 9001, 14001, 27001 y 45001",
  "Tailored risk evaluation criteria": "Criterios de evaluación de riesgos personalizados",
  "Collaborative tools for shared assessments with robust access controls":
    "Herramientas colaborativas para evaluaciones compartidas con controles de acceso robustos",
  "Discover our plans and upgrade your license": "Descubra nuestros planes y adquiera su licencia",
};

export const languageConfiguration = (language) => {
  i18nLanguage = language;
};

export const t = (text) => {
  if (i18nLanguage !== "es") return text;
  else return spanish[text] || text;
};
