import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Warning from "./Warning";

import { changeLanguage, changePassword } from "../services/RiskScribeSDK";
import { t, languageConfiguration } from "../services/i18n";

const Settings = ({ language, updateLanguage }) => {
  const navigate = useNavigate();

  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNetworkProblem, setShowNetworkProblem] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showAuthorizationError, setShowAuthorizationError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const password = document.getElementById("password").value;
    const passwordConfirm = document.getElementById("passwordConfirm").value;

    if (!password.length || password !== passwordConfirm) {
      setIsLoading(false);
      setPasswordsDontMatch(true);
    } else {
      try {
        await changePassword(password);
        setIsLoading(false);
        setPasswordChanged(true);
      } catch (error) {
        if (error.name === "APIError") {
          if (error.status === 401) {
            setShowSessionExpired(true);
          } else if (error.status === 403) {
            setShowAuthorizationError(true);
          } else {
            setShowServerError(true);
          }
        } else {
          setShowNetworkProblem(true);
        }
      }
    }
  };

  const handleLanguageChange = (lang) => {
    languageConfiguration(lang);
    updateLanguage(lang);

    changeLanguage(lang).catch((error) => {
      if (error.name === "APIError") {
        if (error.status === 401) {
          setShowSessionExpired(true);
        } else if (error.status === 403) {
          setShowAuthorizationError(true);
        } else {
          setShowServerError(true);
        }
      } else {
        setShowNetworkProblem(true);
      }
    });

    navigate("/");
  };

  return (
    <>
      <div className="container mt-4">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box mb-4">
              <h2 className="is-size-3 font-rubik mb-2">{t("Language setup")}</h2>

              <div className="field">
                <div className="control">
                  <div className="select is-primary">
                    <select id="language" value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
                      <option value="en">English</option>
                      <option value="es">Español</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="box">
              <h2 className="is-size-3 font-rubik mb-2">{t("Password change")}</h2>
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label" htmlFor="password">
                    {t("Password")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="password" id="password" autoFocus />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="passwordConfirm">
                    {t("Password confirmation")}:
                  </label>
                  <div className="control">
                    <input className="input is-primary" type="password" id="passwordConfirm" />
                  </div>
                </div>

                <div className="field is-flex is-justify-content-center">
                  <div className="control">
                    <button type="submit" className={`button is-primary ${isLoading ? "is-loading" : ""}`}>
                      {t("OK")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Warning show={passwordsDontMatch} onConfirm={() => setPasswordsDontMatch(false)} title={t("Error")} content={t("Passwords do not match")} />
      <Warning
        show={passwordChanged}
        onConfirm={() => {
          setPasswordChanged(false);
          navigate("/");
        }}
        title={t("Notice")}
        content={t("Password successfully changed")}
      />
      <Warning
        show={showNetworkProblem}
        onConfirm={() => {
          setShowNetworkProblem(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("Unable to connect to the server. Please check your Internet connection and try again.")}
      />
      <Warning
        show={showSessionExpired}
        onConfirm={() => {
          setShowSessionExpired(false);
          navigate("/login");
        }}
        title={t("Error")}
        content={t("Your session has expired, please login again.")}
      />
      <Warning
        show={showServerError}
        onConfirm={() => {
          setShowServerError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t("There was a server error processing your request. Please try again in a while and contact support if the problem keeps happening.")}
      />
      <Warning
        show={showAuthorizationError}
        onConfirm={() => {
          setShowAuthorizationError(false);
          navigate("/");
        }}
        title={t("Error")}
        content={t(
          "Your attempt to perform a request without proper authorization has been logged and will be investigated. If you believe this to be an error, please contact support."
        )}
      />
    </>
  );
};

export default Settings;
